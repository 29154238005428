<template>
  <div>
    <v-dialog v-model="dialog" max-width="600px" style="margin:auto;">
      <v-card>
        <br>
        <div class="labelQuestion">
          <v-card-text class="question">¿Estás seguro de que deseas cerrar sesión?</v-card-text>
        </div>
        <div class="buttons">
          <v-card-actions>
            <v-btn class="buttonClose" text @click="closeDialog">Cancelar</v-btn>
            <v-btn class="buttonLogOut" text @click="logout">Cerrar sesión</v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>

    <div class="left-sidebar">
        <div class="user-profile">
            <img class="image-user" width="100" alt="user-icon" :src="userIcon">
        </div>
        <div class="user-info">
                <div class="user-name">{{ userName }}</div>
                <div class="user-email">{{ userEmail }}</div>
        </div>
        <div class="menu-list">
          <ul>
            <!-- <li @click="home()"><img class="icon" width="20" alt="home-icon" :src="homeIcon">Inicio</li> -->
            <li v-if="this.role != 'Admin'" @click="quote()"><img class="icon" width="20" alt="quote-icon" :src="quoteIcon">Cotizar</li>
            <li v-if="this.role == 'Admin'" @click="users()"><img class="icon" width="20" alt="users-icon" :src="usersIcon">Usuarios</li>
            <li @click="myQuote()"><img class="icon" width="20" alt="myquote-icon" :src="myQuoteIcon">Mis Cotizaciones</li>
            <!-- <li @click="config()"><img class="icon" width="20" alt="config-icon" :src="configIcon">Configuración</li> -->
            <li @click="out()"><img class="icon" width="20" alt="out-icon" :src="outIcon">Cerrar Sesión</li>
          </ul>          
        </div>
        <div class="menu-logo">
          <img class="" width="200" alt="menu-southbridge" :src="menuLogo">
        </div>
    </div>
    <div class="content">
        <component :is="showComponent" @cambiar-componente="handlerCambiarComponente" @add-user="handlerCambiarComponente" />
    </div>
  </div>
</template>



<script>

import userIcon from "@/assets/images/user.svg";
import swal from 'sweetalert';
import LogoSB from "@/assets/images/LogoSouthBridge.png";
import homeIcon from "@/assets/images/home.svg";
import configIcon from "@/assets/images/config.svg";
import outIcon from "@/assets/images/out.svg";
import myQuoteIcon from "@/assets/images/myQuote.svg";
import usersIcon from "@/assets/images/users.svg";
import quoteIcon from "@/assets/images/quote.svg";
import menuLogo from "@/assets/images/Logo-South.png";

import Inicio from "@/components/Inicio.vue";
import Step1 from "@/components/Step1.vue";
import Users from "@/components/Users.vue";
import Plans from "@/components/PlanForm.vue";
import Step4 from "@/components/Step4.vue";
import Finish from "@/components/Finish.vue";
import Quotations from "@/components/Quotations.vue";
import AddUser from "@/components/AddUser.vue";



export default {
  name: "Home",
  components: {
    Step1,
    Step4,
    Plans,
    Finish,
    Inicio,
    Quotations
  },
  data: () => ({
    LogoSB,
    userIcon,
    homeIcon,
    configIcon,
    outIcon,
    myQuoteIcon,
    usersIcon,
    quoteIcon,
    menuLogo,
    key: '7cd49960-8289-4e75-adb9-d1503fec31e0',
    role: null,
    valid: false,
    clienteNuevo: null,
    userName:null,
    userEmail:null,
    userRut:null,
    password:null,
    newRut: "",
    version: null,
    dialog: false,
    screen: window.screen.width,
    showComponent: Quotations
  }),
  async mounted() {
    // console.log('HOME') 
    if(!localStorage.getItem('LoginStatus')){
        swal({
            title: "¡Ups!",
            text: "Por favor, inicia sesión para continuar.",
            icon: "info",
            button: "Ok",
        }).then(() => {
            this.$router.push({ path: '/' });
        });
    } 
    if(navigator.userAgent.toLowerCase().indexOf('firefox') > -1){
      document.body.classList.add('firefox');
    }
    this.role = this.decrypt(localStorage.getItem('role'),this.key)
    this.userName = localStorage.getItem('finalUserName')
    this.userRut = localStorage.getItem('finalUserRut')
    this.userEmail = localStorage.getItem('finalUserEmail')

  },
  methods: {
    encrypt(text, key) {
      let result = '';
      for (let i = 0; i < text.length; i++) {
          result += String.fromCharCode(text.charCodeAt(i) ^ key.charCodeAt(i % key.length));
      }
      return btoa(result); 
    },
    decrypt(text, key) {
      text = atob(text); 
      let result = '';
      for (let i = 0; i < text.length; i++) {
          result += String.fromCharCode(text.charCodeAt(i) ^ key.charCodeAt(i % key.length));
      }
      return result;
    },
    async validate() {
      if (this.$refs.form.validate()) {
        console.log("Success");
        
      }else{
        swal({
              title: "Atención!",
              text: "Debes ingresar tu Rut y Contraseña",
              icon: "info",
              button: "De acuerdo",
        });
      }
    },
    home() {
      // console.log("home");
      this.showComponent = Inicio

    },
    quote() {
      // console.log("quote");
      this.showComponent = Step1
    },
    users() {
      // console.log("quote");
      this.showComponent = Users
    },
    myQuote() {
      // console.log("myquote");
      this.showComponent = Quotations
    },
    // config() {
    //   console.log("config");
    // },
    out() {
      // console.log("out");
      this.openDialog()
    },
    openDialog(){
      this.dialog = true
    },
    closeDialog(){
      this.dialog = false
    },
    logout(){
      localStorage.removeItem('Login');
      this.$router.push({ path: '/' });
    },
    handlerCambiarComponente(data) {
      // console.log('El evento cambiar-componente ha sido recibido:',data);
      switch (data) {
        case 'step1':
          this.showComponent = Plans;
          break;

        case 'planes':
          this.showComponent = Step4;
          break;

        case 'step4':
          this.showComponent = Finish;
          break;
          
        case 'finish':
          this.showComponent = Quotations;
          break;

        case 'back1':
          this.showComponent = Step1;
          break;

        case 'back2':
          this.showComponent = Plans;
          break;

        case 'back3':
          this.showComponent = Step4;
          break;

        case 'addUser':
          this.showComponent = AddUser;
          break;

        case 'users':
          this.showComponent = Users;
          break;
          
        default:
          console.error(`Componente no reconocido: ${data}`);
          break;
    }
      
    }

  },
};
</script>




<style lang="scss" scoped>
.menu-list{
  margin: auto;
  margin-top: 20%;

}
ul{
  list-style-type: none;
}

ul li{
  text-align: left;
  cursor: pointer;
  margin-bottom: 8%;
  margin-left: 0%;
}

.left-sidebar{
    background-color: #2D387C;
    float: left;
    width: 17%;
    height: 100vh;
    z-index: 1;
    text-align: center;
    color: white ;
}
.user-profile{
    margin: auto;
    margin-top: 15%;
    margin-bottom: 10%;
}
.image-user {
  width: 43px;
  height: 54px;
  flex-shrink: 0; 
  margin: auto;
}
.user-name{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 35px;
  word-wrap: break-word;
}
.user-email{
  font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    word-wrap: break-word; 
}
.icon{
  margin-right: 5%;
  margin-bottom: -0.5%;
}
.menu-logo{
  position: relative;
  bottom: -42%;
  left: 48%;
  transform: translateX(-50%);
}
.content{
  margin-left: 18%;
  overflow-y: auto;
  height: 100vh;
  width: 81%;
}
.content::-webkit-scrollbar {
  display: none;
}
.labelQuestion{ 
  margin: auto; 
  text-align: center;
}
.question{
  color: var(--AD-Primario-1, #2D387C);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.343px;
}
.buttons{
  margin: auto;
  padding: 5%;
  margin-left: 17%;
}
.buttonClose{
  background-color:#F06C6C;
  color: white;
  width: 150px;
  height: 34px;
  margin-right: 4%;
}
.buttonLogOut{
  background-color:#51B9A4;
  color: white;
  width: 150px;
  height: 34px;
}


.firefox .content {
    scrollbar-width: none; 
}
.firefox .content::-webkit-scrollbar {
    scrollbar-color: transparent transparent;
}
</style>