import axios from "axios";
// const { VUE_APP_SHIELD_URL, VUE_APP_SPONSOR, VUE_APP_PERK_KEY, VUE_APP_CARRO_URL } = process.env;
const { VUE_APP_SHIELD_URL, VUE_APP_PERK_KEY } = process.env;

export default class ShieldService {

  // static async createStep(values, step, version, id) {
  //   try {
  //     var carroRes;
  //     var data = {};
  //     var lenght = 0;
      
      // //Para el primer step del formulario
      // if (step == 1) {
      //   //Para el primera interacción
      //   if (version == 1) {
      //     data = {
      //       name: values[0],
      //       email: values[1],
      //       phoneNumber: values[2],
      //     };
      //     const params = {
      //       sponsor: VUE_APP_SPONSOR,
      //       stepOne: data,
      //       lenght: 1,
      //     };

      //     carroRes = await axios.post(
      //       `${VUE_APP_CARRO_URL}/step/create`,
      //       params
      //     );
      //   }
      //   //Para las demás interacciones interacción
      //   else {
      //     values.forEach((element) => {
      //       if (element != null) {
      //         lenght = lenght + 1;
      //       }
      //     });
      //     data = {
      //       name: values[0],
      //       email: values[1],
      //       phoneNumber: values[2],
      //     };
      //     const params = {
      //       sponsor: VUE_APP_SPONSOR,
      //       stepOne: data,
      //       id: id,
      //       lenght: lenght,
      //     };

      //     carroRes = await axios.put(
      //       `${VUE_APP_CARRO_URL}/step/update`,
      //       params
      //     );
      //   }
      // }

      // //Para el segundo step del formulario
      // else if (step == 2) {
      //   const params = {
      //     sponsor: VUE_APP_SPONSOR,
      //     steptwo: values,
      //     id: id,
      //     lenght: 8,
      //   };

      //   carroRes = await axios.put(`${VUE_APP_CARRO_URL}/step/update`, params);
      // }
      // //Para el tercer step del formulario
      // else if (step == 3) {
      //   values.forEach((element) => {
      //     if (element != null) {
      //       lenght = lenght + 1;
      //     }
      //   });
      //   lenght = lenght + 1;
      //   data = {
      //     species: values[0],
      //     breed: values[1],
      //     petName: values[2],
      //     petAge: values[3],
      //     sex: values[4],
      //     hasMicrochip: values[5],
      //   };
      //   const params = {
      //     sponsor: VUE_APP_SPONSOR,
      //     stepthree: data,
      //     id: id,
      //     lenght: lenght,
      //   };

      //   carroRes = await axios.put(`${VUE_APP_CARRO_URL}/step/update`, params);
      // }

      // else if (step == 4) {
      //   values.forEach((element) => {
      //     if (element != null) {
      //       lenght = lenght + 1;
      //     }
      //   });
      //   lenght = lenght + 1;
      //   data = {
      //     name: values[0],
      //     surname: values[1],
      //     serialNumber: values[2],
      //     rut: values[3],
      //     region: values[4],
      //     commune: values[5],
      //     address: values[6],
      //     email: values[7],
      //     phoneNumber: values[8],
      //     species: values[9],
      //     breed: values[10],
      //     petName: values[11],
      //     petAge: values[12],
      //     sex: values[13],
      //     hasMicrochip: values[14],
      //   };
      //   const params = {
      //     sponsor: VUE_APP_SPONSOR,
      //     stepthree: data,
      //     id: id,
      //     lenght: lenght,
      //   };

      //   carroRes = await axios.put(`${VUE_APP_CARRO_URL}/step/update`, params);
      // }

      // return carroRes;
  //   } catch (error) {
  //     return {
  //       status: "error",
  //       message: "Ocurrió un error al crear el Step",
  //     };
  //   }
  // }

  // static async createContactActiveCampain(element) {

  //   try {
  //     const shieldRes = await axios.post(`${VUE_APP_SHIELD_URL}/activeCampain/createContact`,
  //       {
  //         element,
  //         sponsor: VUE_APP_SPONSOR,
  //       }
  //     );

  //     console.log("respuesta de shield: ", shieldRes.data)

  //     return shieldRes.data;

  //   } catch (error) {
  //     return {
  //       status: "error",
  //       message: "Ocurrió un error al crear el contacto",
  //     };
  //   }
  // }

  // static async updateContactActiveCampain(element, planData, petData, contactID, index) {

  //   try {

  //     const shieldRes = await axios.put(`${VUE_APP_SHIELD_URL}/activeCampain/updateContact`,
  //       {
  //         element,
  //         planData,
  //         petData,
  //         contactID,
  //         index,
  //         sponsor: VUE_APP_SPONSOR,
  //       }
  //     );

  //     return shieldRes.data;

  //   } catch (error) {
  //     return {
  //       status: "error",
  //       message: "Ocurrió un error al actualizar el contacto",
  //     };
  //   }
  // }

  // static async getContactActiveCampain(email) {

  //   try {

  //     const shieldRes = await axios.get(`${VUE_APP_SHIELD_URL}/activeCampain/getContact/${email}`);

  //     return shieldRes.data;

  //   } catch (error) {
  //     return {
  //       status: "error",
  //       message: "Ocurrió un error al actualizar el contacto",
  //     };
  //   }
  // }

  //Lógica para redireccionar los planes cuando tiene un código de descuento
  static async getPlans(daysNumber,planType) {

    try {
      if (planType == "Nacional/Internacional"){
        // planType = "plan_nacional"
          planType = "plan_nacional_internacional"
      }else if (planType === "Internacional") {
        planType = "plan_internacional";
      } else {
        planType = "plan_nacional_internacional"
      }
      const shieldRes = await axios.get(
        //Entrega URL 
        `${VUE_APP_SHIELD_URL}/travelCorporative/?days=${daysNumber}&type=${planType}`
      );
      return shieldRes.data.status === "ok" ? shieldRes.data.data : [];
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener los planes",
      };
    }
  }

  static async getUserId(userRut,token) {
    try {
      const shieldRes = await axios.get(
        //Entrega URL 
        `${VUE_APP_SHIELD_URL}/travelCorporative/getUser?rut=${userRut}`,
        {
          headers: {
            "x-captcha-authorization": token,
          },
        }
      );
      return shieldRes.data.status === "ok" ? shieldRes.data.data : [];
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener el ID del usuario",
      };
    }
  }

  static async getClientId(clientRut) {
    try {
      const shieldRes = await axios.get(
        `${VUE_APP_SHIELD_URL}/travelCorporative/getClient?rut=${clientRut}`
      );
      return shieldRes.data.status === "ok" ? shieldRes.data.data : [];
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener el ID del cliente",
      };
    }
  }

  static async getClientData(clientId) {
    try {
      const shieldRes = await axios.get(
        `${VUE_APP_SHIELD_URL}/travelCorporative/getClientData?id=${clientId}`
      );
      return shieldRes.data.status === "ok" ? shieldRes.data.data : [];
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener la información del cliente",
      };
    }
  }
  static async getCompanyId(companyRut) {
    try {
      const shieldRes = await axios.get(
        `${VUE_APP_SHIELD_URL}/travelCorporative/getCompany?rut=${companyRut}`
      );
      return shieldRes.data.status === "ok" ? shieldRes.data.data : [];
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener el ID del cliente",
      };
    }
  }
  
  static async getUserQuotes(userId,token) {
    try {
      const shieldRes = await axios.get(
        `${VUE_APP_SHIELD_URL}/travelCorporative/getQuotes?userId=${userId}`,
        {
          headers: {
            "x-captcha-authorization": token,
          },
        }
      );
      return shieldRes.data.status === "ok" ? shieldRes.data.data : [];
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener las cotizaciones del usuario",
      };
    }
  }
  
  static async getAllQuotes(token) {
    try {
      const shieldRes = await axios.get(
        `${VUE_APP_SHIELD_URL}/travelCorporative/getAllQuotes`,
        {
          headers: {
            "x-captcha-authorization": token,
          },
        }
      );
      return shieldRes.data.status === "ok" ? shieldRes.data.data : [];
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener las cotizaciones del usuario",
      };
    }
  }

  static async getAllUsers(token) {
    try {
      const shieldRes = await axios.get(
        `${VUE_APP_SHIELD_URL}/travelCorporative/getAllUsers`,
        {
          headers: {
            "x-captcha-authorization": token,
          },
        }
      );
      return shieldRes.data.status === "ok" ? shieldRes.data.data : [];
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener losv usuario",
      };
    }
  }
  
  static async getCompanies(companySponsor) {
    try {
      const shieldRes = await axios.get(
        `${VUE_APP_SHIELD_URL}/travelCorporative/getCompanies?companySponsor=${companySponsor}`
      );
      return shieldRes.data.status === "ok" ? shieldRes.data.data : [];
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener las compañias del usuario",
      };
    }
  }
  static async getAllCompanies() {
    try {
      const shieldRes = await axios.get(
        `${VUE_APP_SHIELD_URL}/travelCorporative/getAllCompanies`
      );
      return shieldRes.data.status === "ok" ? shieldRes.data.data : [];
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener las compañias",
      };
    }
  }

  static async getClients(userClients) {
    try {
      const shieldRes = await axios.get(
        `${VUE_APP_SHIELD_URL}/travelCorporative/getClients`,
        {
          params: { userClients }
        }
      );
      return shieldRes.data.status === "ok" ? shieldRes.data.data : [];
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener los clientes del usuario",
      };
    }
  }

  static async getPeople(personID) {

    try {

      const shieldRes = await axios.get(
        `${VUE_APP_SHIELD_URL}/person/get/${personID}`,
        {
          headers: { perk_key: VUE_APP_PERK_KEY },
        }
      );

      return shieldRes.data.status === "ok" ? shieldRes.data.data : [];
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener los planes",
      };
    }
  }
  static async getDollar() {

    try {
      const shieldRes = await axios.get(
        `${VUE_APP_SHIELD_URL}/travelCorporative/getDollar`,
        {
          headers: { perk_key: VUE_APP_PERK_KEY },
        }
      );
      return shieldRes.data.status === "ok" ? shieldRes.data.data : [];
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener el precio del dolar",
      };
    }
  }

  static async login(useremail,password){
    // console.log('login')
    try {
      const shieldRes = await axios.post(
        `${VUE_APP_SHIELD_URL}/user/loginWithEmail`,
        {
          email: useremail,
          password: password
        },
        {
          headers: { perk_key: VUE_APP_PERK_KEY },
        }
      );
      return shieldRes.data.status === "ok" ? shieldRes.data : [];
    } catch (error) {
      return {
        status: "error",
        message: `Ocurrió un error al intentar logear: ${error.message}`,
      };
    }
  }

  // static async createInsurance(person, dataQuote, plan, stepId) {
  static async createInsurance(dataQuote, user , company, poliza, userId, newClient, newCompany, companyIdSelected ) {
    // const jsonData = { user, dataQuote, planId: plan._id, stepId: stepId };
    // console.log('creando insurance')
    const jsonData = {
      dataQuote: {
        "daysNumber": dataQuote.daysNumber,
        "commissionPercent": dataQuote.commissionPercent,
        "insuranceAgentMail": dataQuote.insuranceAgentMail,
        "contractingPartyMail": dataQuote.contractingPartyMail,
        "priceCLP": dataQuote.priceCLP,
        "priceUSD": dataQuote.priceUSD,
        "planId": dataQuote.planId,
        "planType": dataQuote.planType,
        "counter": dataQuote.counter
      },
      userData: {
        "name": user.name,
        "rut": user.rut,
        "email": user.email,
        "address": user.address,
        "birthdate": user.dates,
      },
      companyData: {
        "rut": company.rut,
        "email": company.email,
        "name": company.name,
        "commune":company.comune,
        "address": company.address,
        "phoneNumber": company.phoneNumber,
        "user": userId,
        "companySponsor": company.companySponsor
      },
      polizaData: {
        "nombreCorredor": poliza.nombreCorredor,
        "date": poliza.fecha,
        "namePlan": poliza.namePlan,
      },
      finalUserData:{
        "userId": userId
      },
      selectedData:{
        "newClient": newClient,
        "newCompany": newCompany,
        "companyIdSelected": companyIdSelected,
      }
    };
    try{
    const shieldRes = await axios.post(
      `${VUE_APP_SHIELD_URL}/travelCorporative/create`,
      jsonData
    );
    
    if (shieldRes.data.status === "ok") return shieldRes.data.data;
    else throw shieldRes.data;
    }catch (error) {
      console.error('Error al realizar la solicitud:', error);
      throw error; // Puedes manejar el error según tus necesidades
    }
  }

  static async createPdf(id,counter,token) {
    try{
      // console.log("este es el id que se envia a shield")
      // console.log(id)
      const shieldRes = await axios.post(
        `${VUE_APP_SHIELD_URL}/travelCorporative/createPdfTravel`,
        {
          id: id,
          counter: counter
        }, 
        {
          headers: {
            'Content-Type': 'application/json',
            "x-captcha-authorization": token,
          }
        }
      );  

      // console.log(shieldRes.data)

      if (shieldRes.data.status === "ok") return shieldRes.data.data;
      else throw shieldRes.data;
      }catch (error) {
        console.error('Error al realizar la solicitud:', error);
        throw error; // Puedes manejar el error según tus necesidades
      }
  }
  static async getPdf(nCounter,token){
    try {
      const shieldRes = await axios.post(`${VUE_APP_SHIELD_URL}/travelCorporative/getPdf`,
        {
          nCounter: nCounter
        },
        {
          headers: {
            'Content-Type': 'application/json',
            "x-captcha-authorization": token,
          }
        }
      );
      return shieldRes.data;

    } catch (error) {
      return {
        status: "error",
        message: "Ocurrió un error al obtener el Pdf",
      };
    }
  }
  static async updatePerson(clientId,name,rut,email,date){
    try {
      const shieldRes = await axios.put(`${VUE_APP_SHIELD_URL}/travelCorporative/updatePerson`,
        {
          clientId: clientId,
          name: name,
          rut: rut,
          email: email,
          birthdate: date,
        }
      );
      return shieldRes.data;

    } catch (error) {
      return {
        status: "error",
        message: "Ocurrió un error al actualizar el usuario",
      };
    }
  }

  static async updateQuoteEmails(quoteId,insuranceAgentMail,contractingPartyMail){
    try {
      const shieldRes = await axios.put(`${VUE_APP_SHIELD_URL}/travelCorporative/updateQuoteEmails`,
        {
          quoteId: quoteId,
          insuranceAgentMail: insuranceAgentMail,
          contractingPartyMail: contractingPartyMail
        }
      );
      return shieldRes.data;

    } catch (error) {
      return {
        status: "error",
        message: "Ocurrió un error al actualizar la cotización",
      };
    }
  }
  static async updateQuote(arrayIds,status) {

    try {

      const shieldRes = await axios.put(`${VUE_APP_SHIELD_URL}/travelCorporative/update`,
        {
          arrayIds: arrayIds,
          status: status
        }
      );

      return shieldRes.data;

    } catch (error) {
      return {
        status: "error",
        message: "Ocurrió un error al actualizar el contacto",
      };
    }
  }
  static async addCounter(token) {
    try{
      // const jsonData = polizaData
      const shieldRes = await axios.get(
        `${VUE_APP_SHIELD_URL}/travelCorporative/addCounter`,
        {
          headers: {
            'Content-Type': 'application/json',
            "x-captcha-authorization": token,
          }
        }
      );
      return shieldRes.data.data;

      }catch (error) {
        console.error('Error al realizar la solicitud:', error);
        throw error; // Puedes manejar el error según tus necesidades
      }
  }

  static async sendPdf(email, name, base64, token){
    try {
      const jsonData = {
        email:email,
        name: name,
        base64: base64
      }
      const shieldRes = await axios.post(
        `${VUE_APP_SHIELD_URL}/travelCorporative/sendPdf`,
        jsonData,
        {
          headers: {
            'Content-Type': 'application/json',
            "x-captcha-authorization": token,
          }
        }
      );
      if (shieldRes.data.status === "ok") return shieldRes.data.status;
      else throw shieldRes.data;
    } catch (error) {
      console.error('Error al realizar la solicitud:', error);
      throw error; // Puedes manejar el error según tus necesidades
    }
  }

  static async sendEmail2(email, name, base64, token){
    try {
      const jsonData = {
        email:email,
        name: name,
        base64: base64
      }
      const shieldRes = await axios.post(
        `${VUE_APP_SHIELD_URL}/travelCorporative/sendEmail2`,
        jsonData,
        {
          headers: {
            'Content-Type': 'application/json',
            "x-captcha-authorization": token,
          }
        }
      );
      if (shieldRes.data.status === "ok") return shieldRes.data.status;
      else throw shieldRes.data;
    } catch (error) {
      console.error('Error al realizar la solicitud:', error);
      throw error; // Puedes manejar el error según tus necesidades
    }
  }

  static async sendEmail2_2(name, base64, token){
    try {
      const jsonData = {
        name: name,
        base64: base64
      }
      const shieldRes = await axios.post(
        `${VUE_APP_SHIELD_URL}/travelCorporative/sendEmail2_2`,
        jsonData,
        {
          headers: {
            'Content-Type': 'application/json',
            "x-captcha-authorization": token,
          }
        }
      );
      if (shieldRes.data.status === "ok") return shieldRes.data.status;
      else throw shieldRes.data;
    } catch (error) {
      console.error('Error al realizar la solicitud:', error);
      throw error; 
    }
  }

  static async sendEmail3(email, name, namePlan, daysNumber, clienteNuevo, nameCliente, rut, date, emailCliente, vigencia, fecha, token){
    try {
      // console.log(email);
      // console.log("EL PLAN EN SHIELD SERVICE: " + namePlan);
      const jsonData = {
        email:email,
        name: name,
        plan: namePlan,
        daysNumber: daysNumber,
        // vigencia: vigencia,
        clienteNuevo: clienteNuevo,
        nameCliente: nameCliente,
        rut: rut,
        date: date,
        emailCliente: emailCliente,
        vigencia: vigencia,
        fecha: fecha

      }
      const shieldRes = await axios.post(
        `${VUE_APP_SHIELD_URL}/travelCorporative/sendEmail3`,
        jsonData,
        {
          headers: {
            'Content-Type': 'application/json',
            "x-captcha-authorization": token,
          }
        }
      );
      if (shieldRes.data.status === "ok") return shieldRes.data.status;
      else throw shieldRes.data;
    } catch (error) {
      console.error('Error al realizar la solicitud:', error);
      throw error; // Puedes manejar el error según tus necesidades
    }
  }

  static async createUser( name, rut, phoneNumber, email, password, company, token){
    try {
      const jsonData = {
        name: name,
        rut: rut,
        phoneNumber: phoneNumber,
        email: email,
        company: company,
        password:password
        
      }
      const shieldRes = await axios.post(
        `${VUE_APP_SHIELD_URL}/travelCorporative/createUser`,
        jsonData,
        {
          headers: {
            'Content-Type': 'application/json',
            "x-captcha-authorization": token,
          }
        }
      );
      return shieldRes.data;
    } catch (error) {
      console.error('Error al realizar la solicitud:', error);
      throw error; // Puedes manejar el error según tus necesidades
    }
  }

  static async deleteUser(userId, token){
    try {
      const shieldRes = await axios.delete(
        `${VUE_APP_SHIELD_URL}/travelCorporative/deleteUser`,
        {
          headers: {
            'Content-Type': 'application/json',
            "x-captcha-authorization": token,
          },
          params: { userId }
        }
      );
      if (shieldRes.data.status === "ok") return shieldRes.data.status;
      else throw shieldRes.data;
    } catch (error) {
      console.error('Error al eliminar Usuario:', error);
      throw error;
    }
  }
}
