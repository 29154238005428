<template>
    <div>
        <div class="saludo-div">
              <div class="contenedor-inicio">
                <img class="image-sun" alt="sun-icon" :src="Sun" />
                <p class="saludo">Buenos días</p>
              </div>
              <p class="usuario-name">¡Hola Usuario!</p>
              <hr class="linea-negra" />
              <br />
        </div>
    </div>
</template>
<script>
import Sun from '@/assets/images/sun-inicio.png';

export default {
  name: "Inicio",
  data: () => ({
    Sun,
    valid: false,
    clienteNuevo: null,
    username:null,
    password:null,
    newRut: "",
    version: null,
    dialog: false,
    screen: window.screen.width,
  }),
  mounted() {
    // console.log('INIT')  
  },
  methods: {
    async validate() {
        console.log("Success")
    },
  },
};
</script>
<style lang="scss">

.contenedor-inicio{
    padding-top: 4%;
    padding-bottom: 1%;
    display: flex;
    align-items: center;
    height: 10px;
    gap: 10px;
}
.image-sun {
    width: 21px;
    height: 21px; 
    flex-shrink: 0;
}
.saludo {
    color: var(--AD-Primario-2, #4F74E3);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 50px;
    letter-spacing: 0.667px;
    margin-top: 1.8%;
}
.usuario-name {
    color: var(--AD-Primario-1, #2D387C);
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 50px;
    letter-spacing: 0.667px;
}

</style>