<template>
    <div>
        <br><br>
        <div class="saludo-div text-center">
              <div class="contenedor-inicio">
                <img class="image-sun" alt="sun-icon" :src="Sun" />
                <p class="saludo">Buenos días</p>
              </div>
              <p class="usuario-name">¡HOLA {{ userName }}!</p>
              <hr class="linea-negra" />
              <br />
        </div>
        <div>
            <v-btn class="buttonAdd" @click="addUser()">Agregar Usuario</v-btn>
        </div>
        <template>
            <div  v-if="this.downloadQuote == false && this.declineQuote == false" >
                <v-form ref="form" v-model="valid"  class="form-container-2">
                  <!-- <v-text-field class="v-text-field-style-2" v-model="filters.numQuote" :rules="filterQuoteNumberRules" label="N°Cotización"  solo ></v-text-field> -->
                  <v-text-field class="v-text-field-style-2" v-model="filters.rutClient" :rules="filterRutRules"  @input="changeRut" label="Rut Cliente"  solo></v-text-field>
                  <v-text-field class="v-text-field-style-2" v-model="filters.nameClient" :rules="filterNameRules" label="Nombre Cliente"  solo></v-text-field>
                  <v-text-field class="v-text-field-style-2" v-model="filters.emailClient" :rules="filterEmailRules" label="Correo Cliente"  solo></v-text-field>
                  <!-- <v-select class="v-select-style-2"  :items="quoteStates" v-model="filters.stateQuote" 
                  label="Estado" return-object
                  solo no-data-text="No hay Estados Disponibles" item-text="value" ></v-select> -->
                  <v-btn class="buttonFilter" @click="getFilterUsers()"><v-icon>mdi-magnify</v-icon></v-btn>
                  <v-btn class="buttonFilterRefresh" @click="getFilterRefresh()"><v-icon>mdi-refresh</v-icon></v-btn>
                </v-form>
              <br><br>
              <v-data-table :footer-props="{ 'items-per-page-text': '' }" :headers="headersFinal" :items="cotizaciones"
                  :items-per-page="isMobile ? 1 : 10" sort-by="price" class="elevation-1 table"
                  no-data-text="NO HAY REGISTROS">
            
                <template v-slot:item.delete="{ item }">
                    <v-btn color="red" @click="deleteUser(item)">
                    <v-icon color="white">mdi-delete</v-icon>
                    </v-btn>
                </template>
              </v-data-table>
            </div>
        </template> 
        <div v-if="this.downloadQuote == true || this.declineQuote == true" class="spinner-container">
          <i class="mdi mdi-loading mdi-spin"></i>
        </div>
        <br><br><br>
        </div>
    </template>

<script>
import ShieldService from '../services/shield.service';
import Vue from "vue";
import Sun from '@/assets/images/sun-inicio.png';
import RutUtils from "@/utils/rut.utils.js";
import { VueReCaptcha } from 'vue-recaptcha-v3'
const { VUE_APP_RECAPTCHA3_SITE_KEY } = process.env;

Vue.use(VueReCaptcha, { siteKey: VUE_APP_RECAPTCHA3_SITE_KEY })

import swal from 'sweetalert';
export default {
  name: "Users",
  data: () => ({
    filterNameRules: [
      (v) => {
        if (v === null || v === '') return true;
        return /^[\p{L}\s-]+$/u.test(v) || "Ingrese solo letras";
      },
      (v) => {
        if (v === null || v === '') return true;
        return !v || v.length <= 50 || "Debe ingresar máximo 50 carácteres";
      },
    ],
    filterEmailRules: [
        (v) => {
            if (v === null || v === '') return true;
            return /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(v) || 'Ingrese un email válido'; 
        },
        (v) => {
            if (v === null || v === '') return true; 
            return v.length <= 50 || 'El email debe tener máximo 50 caracteres'; 
        },
    ],
    filterRutRules: [
      (v) => RutUtils.validateRut(v) || "Rut no válido",
    ],
    valid: false,
    Sun,
    newRut: null,
    role: null,
    key: '7cd49960-8289-4e75-adb9-d1503fec31e0',
    version: null,
    dialog: false,
    downloadQuote: false,
    sendingOut: false,
    sendingEmails: false,
    declineQuote: false,
    filters: {
      rutClient: null,
      nameClient: null,
      emailClient: null
    },
    login: '',
    userRut: null,
    userName: null,
    vigencia: null,
    cotizaciones: [],
    cotizacionOriginal: [],
    cotizacionFiltro: [],
    quoteStates: [
      { key: 'Pending', value: 'Pendiente' },
      { key: 'Generated', value: 'Emitido' },
      { key: 'Rejected', value: 'Rechazado' }
    ],
    fecha:null,
    selectedQuoteData: null,
    screen: window.screen.width,
    headers: [
      { text: 'Nombre Cliente', value: 'name', align: 'left', sortable: false, width: '430px' },
      { text: 'Rut', value: 'rut', align: 'left', sortable: false, width: '140px' },
      { text: 'Correo', value: 'email', align: 'left', sortable: false },
      { text: 'Eliminar', value: 'delete', align: 'center', sortable: false},
    ],
  }),
  async mounted() {
    // console.log('COTIZACIONES')  
    this.userName = localStorage.getItem('finalUserName')
    this.userRut = localStorage.getItem('finalUserRut')
    try {
        const userId = await this.getUserId();
        this.userId = userId;
    } catch (error) {
        console.error('Error obteniendo userId:', error);
    }

    this.role = this.decrypt(localStorage.getItem('role'),this.key)


    this.getAllUsers()


    this.headersFinal = this.headers
    


    var newDate  = new Date();
    newDate = newDate.toISOString().slice(0, 10).split("-")
    this.vigencia = newDate[2] + "/" + newDate[1] + "/" + (parseInt(newDate[0]) + 1)
    this.fecha = newDate[2] + "/" + newDate[1] + "/" + newDate[0]
  },
  methods: {
    encrypt(text, key) {
      let result = '';
      for (let i = 0; i < text.length; i++) {
          result += String.fromCharCode(text.charCodeAt(i) ^ key.charCodeAt(i % key.length));
      }
      return btoa(result); 
    },
    decrypt(text, key) {
      text = atob(text); 
      let result = '';
      for (let i = 0; i < text.length; i++) {
          result += String.fromCharCode(text.charCodeAt(i) ^ key.charCodeAt(i % key.length));
      }
      return result;
    },
    changeRut(rut) {
      this.filters.rutClient = RutUtils.cleanRut(rut);
      this.newRut = RutUtils.formatRut(rut);
      this.filters.rutClient = this.newRut;
    },
    async addUser() {
        this.$emit('add-user','addUser');
    },
    async getUserId() {
        await this.$recaptchaLoaded()
        const token = await this.$recaptcha('getUserId')
        try {
          const response = await ShieldService.getUserId(this.userRut,token);
          // console.log('response: ',response)
          return response._id;
        } catch (error) {
          console.error('Error obteniendo userId:', error);
          throw error;
        }
    },
    async getUserQuotes(){
      await this.$recaptchaLoaded()
      const token = await this.$recaptcha('getUserQuotes')
      try {
          const response = await ShieldService.getUserQuotes(this.userId,token);
          this.cotizacionOriginal = response
          this.cotizaciones = this.cotizacionOriginal
          // this.cotizaciones.forEach(async cotizacion => {
          //   console.log(cotizacion);
          // });
      } catch (error) {
          console.error('Error obteniendo cotizaciones:', error);
          throw error;
      }
    },
    async getAllUsers(){
      await this.$recaptchaLoaded()
      const token = await this.$recaptcha('getAllUsers')
      try {
          const response = await ShieldService.getAllUsers(token);
          this.cotizacionOriginal = response
          this.cotizaciones = this.cotizacionOriginal
      } catch (error) {
          console.error('Error obteniendo usuarios:', error);
          throw error;
      }
    },
    async getFilterRefresh(){
      this.cotizaciones = this.cotizacionOriginal
      this.filters.rutClient = null
      this.filters.nameClient = null
      this.filters.emailClient = null
    },
    async getFilterUsers(){
      try {
          //AGREGAR FILTROS
          if ( this.filters.rutClient != '' && this.filters.rutClient != null || this.filters.nameClient != '' && this.filters.nameClient != null || this.filters.emailClient != '' && this.filters.emailClient != null ){
            
            this.cotizacionFiltro = this.cotizacionOriginal

            
            if (this.filters.rutClient != '' && this.filters.rutClient != null) {
            this.cotizacionFiltro = this.cotizacionFiltro.filter(element => {
                const rutNormalized = element.rut.slice(0, -1) + element.rut.slice(-1).toUpperCase();
                return rutNormalized.includes(this.filters.rutClient);
            });
            }
            if (this.filters.nameClient != '' && this.filters.nameClient != null) {
              this.cotizacionFiltro = this.cotizacionFiltro.filter(element => {
                return element.name.includes(this.filters.nameClient);
              });
            }

            if (this.filters.emailClient != '' && this.filters.emailClient != null) {
              this.cotizacionFiltro = this.cotizacionFiltro.filter(element => {
                return element.email.includes(this.filters.emailClient);
              });
            }

            if(this.cotizacionFiltro.length > 0){
              this.cotizaciones = this.cotizacionFiltro
            } else{
              swal({
                title: "¡Ups!",
                text: "No se han encontrado usuarios.",
                icon: "info",
                button: "Ok",
              })
              this.cotizaciones = this.cotizacionOriginal
              this.filters.numQuote = null
            }
          } else {
            swal({
              title: "¡Ups!",
              text: "Por favor, ingresa datos para buscar.",
              icon: "info",
              button: "Ok",
            })
          }
      } catch (error) {
          console.error('Error obteniendo usuarios:', error);
          throw error;
      }
    },
    async validate() {
      if (this.$refs.form.validate()) {
        // console.log("Success");
        this.login = 'Success'
        localStorage.setItem("Login",this.login)
        // this.$router.push({path:"/home"});
        window.location.href = "/home";
      }
    },
    cleanRadioButtons(){
        this.cotizaciones.forEach(item => {
            item.select = false;
        });
    },
    async validateChangeStatus(array){
      var arraySelectedItems = array.map(registro => registro.status );
      const existsPending = arraySelectedItems.includes('Pending');
      if (existsPending) {
        return true
      } else {
        return false
      }
    },
    async deleteUser(item){
      const isConfirmed = confirm("¿Está seguro de que desea eliminar este registro?");
      if (!isConfirmed) {
        return; 
      }
      await this.$recaptchaLoaded()
      const token = await this.$recaptcha('deleteUser')
      try {
          const response = await ShieldService.deleteUser( item._id , token);
          if (response == 'ok') {
            swal({
              title: "¡Excelente!",
              text: "Usuario eliminado correctamente.",
              icon: "success",
              button: "Ok",
          });
            this.getAllUsers();
          } else {
            swal({
            title: "Error!",
            text: "Ocurrió un error al eliminar usuario. Por favor, vuelve a intentarlo.",
            icon: "error",
            button: "Ok",
          });
          }
      } catch (error) {
          console.error('Error eliminando usuario:', error);
          throw error;
      }
    },
  },
};
</script>

<style>
.v-select-style-2{
  height: 50px;
  background: #ffffff;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
  width: 300px;
}
.filter-container{
  display: flex;
  flex-direction: column;
  background-color: yellow;
}
.contenedor-inicio{
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-sun {
    width: 21px;
    height: 21px; 
    flex-shrink: 0;
}
.saludo {
    color: var(--AD-Primario-2, #4F74E3);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 50px;
    letter-spacing: 0.667px;
    margin-top: 1.8%;
}
.buttonFilter{
    background-color: #2d387c !important;
    color: white !important;
    border-radius: 6px;
    margin-left: 1%;
    height: 50px !important;
    font-size: 24px !important;
    /* width: 80% !important; */
    /* height: 120% !important; */
}
.buttonAdd{
    background-color: #2d387c !important;
    color: white !important;
    border-radius: 6px;
    height: 40px !important;
    font-size: 16px !important;
    margin-bottom: 4% !important;
    /* width: 80% !important; */
    /* height: 120% !important; */
}
.linea-negra {
  border: none;
  width: 100%;
  height: 5px;
  flex-shrink: 0;
  background: var(--Degradado-vibrante, linear-gradient(90deg, #A74CF2 8.2%, #658BFC 91.27%));
}
.buttonFilterRefresh{
    background-color: #2d387c !important;
    color: white !important;
    border-radius: 6px;
    margin-left: 1%;
    height: 50px !important;
    font-size: 24px !important;
    /* width: 80% !important; */
    /* height: 120% !important; */
}
.v-text-field-style-2{
  /* box-sizing: border-box; */
  height: 50px !important;
  width: 300px;
  /* background: yellow; */
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}
.form-container-2{
  /* background-color: yellowgreen; */
  display: flex;
  gap: 1%;
  width: 100% !important;
}
.tableHeaders {
  background-color: #2D387C;
  color: white !important;
  border: 2px solid #2D387C;
}

.table {
  border: 2px solid #2D387C;
  width: 100% !important;
}

.tableButton{
    color: white !important;
    background-color: #2D387C !important;
    margin-left: 1%;
    margin-bottom: 1%;
    margin-top: 1%;
}
.spinner-container {
  display: flex; 
  justify-content: center; 
  align-items: center;
  height: 100%;
  font-size: 300px;
}
.spinner {
  display: flex; 
  justify-content: center; 
  align-items: center; 
}
.spinner i {
  animation: spin 1s linear infinite; 
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); } 
}
.v-data-table__wrapper > table:nth-child(1) > tbody:nth-child(3) > tr:nth-child(1) > td:nth-child(1) > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) > div:nth-child() {
margin: auto;
}

</style>

<style lang="css">

.v-data-table>.v-data-table__wrapper>table>thead>tr>th {
  background-color: #2D387C;
  color: white !important;
}

</style>