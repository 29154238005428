// import dateData from "../utils/days-data";
import dateData from "../utils/date-data";


export default class dateService {
  
  static async getDays() {
    try {
      var days = [];
      for (var i = 40; i <= 365; i++) {
        days.push(i);
      }
      // let months;
      // if (typeof dateId === "string" || dateId instanceof String) {
      //   months = dateData.find((element) => element.name === dateId);
      // } else if (!isNaN(parseFloat(dateId)) && !isNaN(dateId - 0)) {
      //   months = dateData.find((element) => element.number === dateId);
      // } else {
      //   return [];
      // }
      // let days = months.days ? months.days : [];
      return days;
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener los días",
      };
    }
  }

  static async getMonths() {
    try {
      let months = dateData;
      return months;
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener los meses",
      };
    }
  }

  static async getDays2(dateId) {
    try {
      let months;
      if (typeof dateId === "string" || dateId instanceof String) {
        months = dateData.find((element) => element.name === dateId);
      } else if (!isNaN(parseFloat(dateId)) && !isNaN(dateId - 0)) {
        months = dateData.find((element) => element.number === dateId);
      } else {
        return [];
      }
      let days = months.days ? months.days : [];
      return days;
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener los días",
      };
    }
  }
  }

  
