<template>
    <v-container>
      <NavBar :step="3" @retroceder="handlerRetrocederComponente" />
  
      <Titulo></Titulo>
  
      <br />
      <br />
  
      <v-stepper v-model="e1" elevation="0">
        <v-stepper-header style="box-shadow: none">
          <v-stepper-step :complete="e1 > 1" step="1"> Inicio </v-stepper-step>
  
          <v-divider></v-divider>
  
          <v-stepper-step :complete="e1 > 2" step="2"> Plan </v-stepper-step>
  
          <v-divider></v-divider>
  
          <!-- <v-stepper-step :complete="e1 > 3" step="3"> Datos </v-stepper-step>
  
          <v-divider></v-divider> -->
  
          <v-stepper-step  :complete="e1 > 3" step="3"> Resumen </v-stepper-step>
  
          <v-divider></v-divider>
  
          <v-stepper-step step="4"> Éxito </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="4">
            <div class="step-data">
                <br />
                <div v-if="loading" class="text-center" style="margin-top:20%">
                <v-progress-circular :size="300" color="primary" indeterminate></v-progress-circular>
                <br><br>{{ cont }}
                </div>
                <div v-else>
                    <br />
                    <h3  class="text-center h3-title">GRACIAS POR CONFIAR EN NOSOTROS</h3>
                    <br />            
                    <div class="message" >
                        <h4 class="text-center messageContent h4-text">
                        Hemos realizado satisfactoriamente la solicitud de carga de días, en un máximo de 48 horas
                        hábiles estarán cargados en su usuario
                        </h4>
                    </div> 
                    <br /><br />
                    <br /><br />

                    <!-- <h3 class="text-center h3-subtitle">¿Quieres ver otros seguros?</h3> -->

                  

                    <!-- <v-btn class="button-download">Descargar Poliza</v-btn> -->

                    <br />
                   

                    <h3 class="text-center h3-subtitle">¿Quieres ver tus cotizaciones?</h3><br />

                    <v-btn  @click="back" class="button-final" style="padding: auto;">
                    Ver Mis Cotizaciones
                    </v-btn>

                    <br />
                    <br />

                    <!-- <h4 class="text-center messageContent h4-text" style="margin-bottom: -2%;">
                        Si tienes problemas, no dudes en comunicarte <br /> con nosotros presionando aquí: 
                    </h4>

                    <v-btn class="button-final" style="padding: auto;">
                    <img :src="Group" alt="back" class="" style="margin-right: 3%;"> 
                    Atención al cliente
                    </v-btn> -->
                </div>    
            </div>
            <br>
            
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-container>
  </template>
  
  <script>
  import NavBar from "@/components/NavBar.vue";
  import Titulo from "../components/Titulo.vue";
  import Back from '@/assets/images/Arrow_Right_MD.png';
  import Group from '@/assets/images/Group.png';

  export default {
    name: "Finish",
    components: { NavBar, Titulo },
    
    data: () => ({
      version: null,
      Back,
      Group,
      e1: 4,
      userData: {
        name: null,
        rut: null,
        address: null,
        email: null,
        phoneNumber: null,
        commissionPercent: null,
        days: null,
        insuranceAgentMail: null,
        contractingPartyMail: null,
        planType: null,
        planId: null
      },
      dialog: false,
      screen: window.screen.width,
      valid: false
    }),
    
    mounted() {
      // console.log(this.userData)
    },
    methods: {
      handlerRetrocederComponente(step){
        // console.log('step: ',step)
        this.$emit('cambiar-componente','back'+step);
      },
      async validate() {
        //console.log("END")
      },
      back() {
        localStorage.removeItem('insuranceAgentMail')
        localStorage.removeItem('contractingPartyMail')
        localStorage.removeItem('userName')
        localStorage.removeItem('userRut')
        localStorage.removeItem('userEmail')
        localStorage.removeItem('userDates')
        localStorage.removeItem('userBirthday')
        localStorage.removeItem('companyName')
        localStorage.removeItem('companyRut')
        localStorage.removeItem('companyAddress')
        localStorage.removeItem('companyComune')
        localStorage.removeItem('companyPhoneNumber')
        localStorage.removeItem('companyEmail')
        localStorage.removeItem('companyRutSelected')
        localStorage.removeItem('counter')
        localStorage.removeItem('planType')
        localStorage.removeItem('daysNumber')
        localStorage.removeItem('clientRutSelected')
        localStorage.removeItem('clienteNuevo')
        localStorage.removeItem('empresaNuevo')
        localStorage.removeItem('idTravelCorporate')
        this.$emit('cambiar-componente','finish');
      }
      
    },
  };
  </script>
  
  <style lang="scss">
  .step-date {
    max-width: 200px;
    align-items: center;
    display: flex;
    margin-left: 2rem;
    justify-content: center;
  
    .v-select {
      max-width: 150px;
    }
  }
  
  .form-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 800px;
  }
  
  #app
    > div
    > main
    > div
    > div
    > div.v-stepper.v-stepper--alt-labels.v-sheet.theme--light.elevation-0
    > div.v-stepper__header
    > div:nth-child(1)
    > span {
    width: 39.89px;
    height: 40px;
    font-size: 2rem;
    margin-top: -8%;
    font-family: "Verdana";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.01em;
    color: #fcfcfc;
  }
  
  #app
    > div
    > main
    > div
    > div
    > div.v-stepper.v-stepper--alt-labels.v-sheet.theme--light.elevation-0
    > div.v-stepper__header
    > div:nth-child(3)
    > span {
    width: 39.89px;
    height: 40px;
    font-size: 2rem;
    margin-top: -8%;
    font-family: "Verdana";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.01em;
    color: #fcfcfc;
  }
  
  #app
    > div
    > main
    > div
    > div
    > div.v-stepper.v-stepper--alt-labels.v-sheet.theme--light.elevation-0
    > div.v-stepper__header
    > div:nth-child(5)
    > span {
    width: 39.89px;
    height: 40px;
    font-size: 2rem;
    margin-top: -8%;
    font-family: "Verdana";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.01em;
    color: #fcfcfc;
  }
  
  #app
    > div
    > main
    > div
    > div
    > div.v-stepper.v-stepper--alt-labels.v-sheet.theme--light.elevation-0
    > div.v-stepper__header
    > div:nth-child(7)
    > span {
    width: 39.89px;
    height: 40px;
    font-size: 2rem;
    margin-top: -8%;
    font-family: "Verdana";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.01em;
    color: #fcfcfc;
  }
  
  .titulo {
    background-image: url("~@/assets/images/titulo2.png");
    max-width: 350px;
    height: 150px;
    margin: auto;
  }
  
  .v-input .v-label {
    font-size: 12px;
    color: #8e8e8e;
  }
  
  .step-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  
    h1 {
      font-weight: 500;
      text-align: center;
      color: #464e71;
      margin-bottom: 2rem;
    }
  
    h2 {
      font-weight: 500;
      color: #464e71;
      margin-bottom: 1rem;
    }
  
    p {
      text-align: center;
    }
  
    .back-container {
      width: 100%;
      align-items: flex-start;
  
      .back-button {
        &:hover {
          opacity: 1;
        }
      }
    }
  
    .button-container {
      text-align: center;
    }
  
    .forecast-selector {
      text-transform: uppercase;
      font-weight: 600;
      color: #464e71;
    }
  }
    hr{
      border: 1px solid #2D387C;
      opacity: 100%;
    }
  
    .centered-box {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 175%);
        padding: 20px;
        border-radius: 5px;
        border: 1px solid #2D387C;
        background-color: white;
        width: 70%;      
    }
    .centered-box-content{
      margin-left: 22%;
      display: flex;
  
    }
    .price{
      font-size: 200%;
      margin-right: 2%;
      font-weight: 600;
      color: #2D387C;
    }
    .alternativePrice{
      font-size: 140%;
      margin-left: 2%;
      color: #2D387C;
  
    }
  
  .v-btn__content {
    padding: 0.75rem;
  }
  .message{
    border: 1px solid grey;
    width: 70%;
    height: 15%;
    flex-shrink: 0;
    align-content: center;
    margin: auto;
    border-radius: 5px;

}
.messageContent{
    padding: 5% !important;
}

.h4-text{
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #232323;
  opacity: 0.75;
  padding: 2%;
}

.h3-title{
  font-family: Helvetica;
  font-style: normal; 
  font-weight: 700; 
  font-size: 20px; 
  line-height: 23px;
  letter-spacing: -0.01em; 
  color: #FFB600; 
  text-transform: uppercase;
}
.h3-subtitle{
  font-family: Helvetica; 
  font-style: normal; 
  font-weight: 700; 
  font-size: 18px; 
  line-height: 15px; 
  letter-spacing: -0.01em; 
  color: #232323; 
  margin-top: -5%;
}
.button-download{
  background-color: #EDB321 !important;
  color: white !important;
  border-radius: 12px;
  width: 50%;
  height: 120% !important;
  padding: auto !important;
}
.button-final{
  background-color: #2d387c !important;
  color: white !important;
  border-radius: 12px;
  width: 50% ;
  height: 120% !important;
  
}
  </style>