<template>
    <div>
        <br><br>
        <div class="saludo-div text-center">
              <div class="contenedor-inicio">
                <img class="image-sun" alt="sun-icon" :src="Sun" />
                <p class="saludo">Buenos días</p>
              </div>
              <p class="usuario-name">¡HOLA {{ userName }}!</p>
              <hr class="linea-negra" />
              <br />
        </div>
        <template>
            <div  v-if="this.downloadQuote == false && this.sendingOut == false && this.declineQuote == false && this.sendingEmails == false" >
                <v-form ref="form" v-model="valid"  class="form-container-2">
                  <v-text-field class="v-text-field-style-2" v-model="filters.numQuote" :rules="filterQuoteNumberRules" label="N°Cotización"  solo ></v-text-field>
                  <v-text-field class="v-text-field-style-2" v-model="filters.rutClient" :rules="filterRutRules"  @input="changeRut" label="Rut Cliente"  solo></v-text-field>
                  <v-text-field class="v-text-field-style-2" v-model="filters.nameClient" :rules="filterNameRules" label="Nombre Cliente"  solo></v-text-field>
                  <v-select class="v-select-style-2"  :items="quoteStates" v-model="filters.stateQuote" 
                  label="Estado" return-object
                  solo no-data-text="No hay Estados Disponibles" item-text="value" ></v-select>
                  <v-btn class="buttonFilter" @click="getFilterUserQuotes()"><v-icon>mdi-magnify</v-icon></v-btn>
                  <v-btn class="buttonFilterRefresh" @click="getFilterRefresh()"><v-icon>mdi-refresh</v-icon></v-btn>
                </v-form>
              <br><br>
              <v-data-table :footer-props="{ 'items-per-page-text': '' }" :headers="headersFinal" :items="cotizaciones"
                  :items-per-page="isMobile ? 1 : 10" sort-by="price" class="elevation-1 table"
                  no-data-text="NO HAY REGISTROS">
            
                  <template v-slot:item.select="{ item }">
                      <v-checkbox style="margin-left:30%;" v-if="item.status != 'Generated' && item.status != 'Rejected'" v-model="item.select"></v-checkbox>
                      <v-checkbox style="margin-left:30%;" v-if="item.status == 'Generated' || item.status == 'Rejected'" v-model="item.select" disabled></v-checkbox>
                  </template>

                  <template v-slot:item.view="{ item }">
                      <!-- <v-icon v-if="item.status != 'Generated' && item.status != 'Sent' && item.status != 'Rejected'  " @click="openDetail(item)" disabled>mdi-file-document</v-icon> -->
                      
                      <v-icon v-if="item.status == 'Generated' || item.status == 'Pending' || item.status == 'Rejected'" @click="openDetail(item)" >mdi-file-document</v-icon>
                  </template>

                  <template v-slot:item.priceUSD="{ item }">
                    <span>${{ item.priceUSD }}</span>
                  </template>

                  <template v-slot:item.status="{ item }">
                    <span>
                      {{ 
                        item.status === 'Pending' ? 'Pendiente' : 
                        item.status === 'Generated' ? 'Emitido' : 
                        item.status === 'Rejected' ? 'Rechazado' : 
                        item.status 
                      }}
                    </span>
                  </template>

                  <template v-if="this.role != 'Admin'"  v-slot:footer>
                      <div class="">
                          <!-- <v-btn class="tableButton" @click="sendEmails">Enviar</v-btn> -->
                          <v-btn class="tableButton" @click="sendOut">Emitir</v-btn>
                          <v-btn class="tableButton" @click="decline">Declinar</v-btn>
                      </div>
                  </template>    
              </v-data-table>
            </div>
        </template>
        <div v-if="this.downloadQuote == true || this.sendingOut == true || this.sendingEmails == true || this.declineQuote == true" class="spinner-container">
          <i class="mdi mdi-loading mdi-spin"></i>
        </div>
        <br><br><br>
    </div>
</template>

<script>
import ShieldService from '../services/shield.service';
import Vue from "vue";
import Sun from '@/assets/images/sun-inicio.png';
import RutUtils from "@/utils/rut.utils.js";
import { VueReCaptcha } from 'vue-recaptcha-v3'
const { VUE_APP_RECAPTCHA3_SITE_KEY } = process.env;

Vue.use(VueReCaptcha, { siteKey: VUE_APP_RECAPTCHA3_SITE_KEY })

import swal from 'sweetalert';
export default {
  name: "Quotations",
  data: () => ({
    filterNameRules: [
      (v) => {
        if (v === null || v === '') return true;
        return /^[\p{L}\s-]+$/u.test(v) || "Ingrese solo letras";
      },
      (v) => {
        if (v === null || v === '') return true;
        return !v || v.length <= 50 || "Debe ingresar máximo 50 carácteres";
      },
    ],
    filterRutRules: [
      (v) => RutUtils.validateRut(v) || "Rut no válido",
    ],
    filterQuoteNumberRules: [
      (v) => {
          if (v === null || v === '') return true;
          return /^\d+$/.test(v) || "Solo se permiten números";
      },
      (v) => {
          if (v === null || v === '') return true;
          return !v || v.length <= 10 || "Debe ingresar máximo 10 carácteres";
      },
    ],
    valid: false,
    Sun,
    newRut: null,
    role: null,
    key: '7cd49960-8289-4e75-adb9-d1503fec31e0',
    version: null,
    dialog: false,
    downloadQuote: false,
    sendingOut: false,
    sendingEmails: false,
    declineQuote: false,
    filters: {
      numQuote: null,
      rutClient: null,
      nameClient: null,
      stateQuote: null,
    },
    login: '',
    userRut: null,
    userName: null,
    vigencia: null,
    cotizaciones: [],
    cotizacionOriginal: [],
    cotizacionFiltro: [],
    quoteStates: [
      { key: 'Pending', value: 'Pendiente' },
      { key: 'Generated', value: 'Emitido' },
      { key: 'Rejected', value: 'Rechazado' }
    ],
    fecha:null,
    selectedQuoteData: null,
    screen: window.screen.width,
    headers: [
      { text: 'Seleccionar', value: 'select', align: 'center', sortable: false, },
      { text: 'N°Cotización', value: 'counter', align: 'center', sortable: false, width: '100px' },
      { text: 'Rut Cliente', value: 'companyRut', align: 'center', sortable: false, width: '140px'  },
      { text: 'Nombre Cliente', value: 'companyName', align: 'center', sortable: false, width: '200px' },
      { text: 'Plan Cotizado', value: 'namePlan', align: 'center', sortable: false, width: '200px' },
      { text: '# Días Cotizados', value: 'daysNumber', align: 'center', sortable: false, },
      { text: 'Prima (USD)', value: 'priceUSD', align: 'center', sortable: false, width:'100px'},
      { text: 'Fecha', value: 'date', align: 'center', sortable: false, width:'100px'},
      { text: 'Estado', value: 'status', align: 'center', sortable: false },
      { text: 'Ver', value: 'view', align: 'center', sortable: false },
    ],
    headersAdmin: [
      { text: 'Seleccionar', value: 'select', align: 'center', sortable: false, },
      { text: 'N°Cotización', value: 'counter', align: 'center', sortable: false, width: '100px' },
      { text: 'Corredor', value: 'corredorName', align: 'center', sortable: false, width: '100px' },
      { text: 'Rut Cliente', value: 'companyRut', align: 'center', sortable: false, width: '140px'  },
      { text: 'Nombre Cliente', value: 'companyName', align: 'center', sortable: false, width: '200px' },
      { text: 'Plan Cotizado', value: 'namePlan', align: 'center', sortable: false, width: '200px' },
      { text: '# Días Cotizados', value: 'daysNumber', align: 'center', sortable: false, },
      { text: 'Prima (USD)', value: 'priceUSD', align: 'center', sortable: false, width:'100px'},
      { text: 'Fecha', value: 'date', align: 'center', sortable: false, width:'100px'},
      { text: 'Estado', value: 'status', align: 'center', sortable: false },
      { text: 'Ver', value: 'view', align: 'center', sortable: false },
    ],
  }),
  async mounted() {
    // console.log('COTIZACIONES')  
    this.userName = localStorage.getItem('finalUserName')
    this.userRut = localStorage.getItem('finalUserRut')
    try {
        const userId = await this.getUserId();
        this.userId = userId;
    } catch (error) {
        console.error('Error obteniendo userId:', error);
    }

    this.role = this.decrypt(localStorage.getItem('role'),this.key)

    if (this.role == 'Admin'){
      this.getAllQuotes()
      this.headersFinal = this.headersAdmin
    } else {
      this.getUserQuotes()
      this.headersFinal = this.headers
    }


    var newDate  = new Date();
    newDate = newDate.toISOString().slice(0, 10).split("-")
    this.vigencia = newDate[2] + "/" + newDate[1] + "/" + (parseInt(newDate[0]) + 1)
    this.fecha = newDate[2] + "/" + newDate[1] + "/" + newDate[0]
  },
  methods: {
    encrypt(text, key) {
      let result = '';
      for (let i = 0; i < text.length; i++) {
          result += String.fromCharCode(text.charCodeAt(i) ^ key.charCodeAt(i % key.length));
      }
      return btoa(result); 
    },
    decrypt(text, key) {
      text = atob(text); 
      let result = '';
      for (let i = 0; i < text.length; i++) {
          result += String.fromCharCode(text.charCodeAt(i) ^ key.charCodeAt(i % key.length));
      }
      return result;
    },
    changeRut(rut) {
      this.filters.rutClient = RutUtils.cleanRut(rut);
      this.newRut = RutUtils.formatRut(rut);
      this.filters.rutClient = this.newRut;
    },
    async getUserId(){
        await this.$recaptchaLoaded()
        const token = await this.$recaptcha('getUserId')
        try {
          const response = await ShieldService.getUserId(this.userRut,token);
          // console.log('response: ',response)
          return response._id;
        } catch (error) {
          console.error('Error obteniendo userId:', error);
          throw error;
        }
    },
    async getUserQuotes(){
      await this.$recaptchaLoaded()
      const token = await this.$recaptcha('getUserQuotes')
      try {
          const response = await ShieldService.getUserQuotes(this.userId,token);
          this.cotizacionOriginal = response
          this.cotizaciones = this.cotizacionOriginal
          // this.cotizaciones.forEach(async cotizacion => {
          //   console.log(cotizacion);
          // });
      } catch (error) {
          console.error('Error obteniendo cotizaciones:', error);
          throw error;
      }
    },
    async getAllQuotes(){
      await this.$recaptchaLoaded()
      const token = await this.$recaptcha('getAllQuotes')
      try {
          const response = await ShieldService.getAllQuotes(token);
          this.cotizacionOriginal = response
          this.cotizaciones = this.cotizacionOriginal
          // this.cotizaciones.forEach(async cotizacion => {
          //   console.log(cotizacion);
          // });
      } catch (error) {
          console.error('Error obteniendo cotizaciones:', error);
          throw error;
      }
    },
    async getFilterRefresh(){
      this.cotizaciones = this.cotizacionOriginal
      this.filters.numQuote = null
      this.filters.rutClient = null
      this.filters.nameClient = null
      this.filters.stateQuote = null;
    },
    async getFilterUserQuotes(){
      try {
          //AGREGAR FILTROS
          if (this.filters.numQuote != '' && this.filters.numQuote != null || this.filters.rutClient != '' && this.filters.rutClient != null || this.filters.nameClient != '' && this.filters.nameClient != null || this.filters.stateQuote != '' && this.filters.stateQuote != null){
            
            this.cotizacionFiltro = this.cotizacionOriginal

            if (this.filters.numQuote != '' && this.filters.numQuote != null ){
              this.cotizacionFiltro = this.cotizacionFiltro.filter(element => {
                const counterAsString = String(element.counter);
                return counterAsString.includes(this.filters.numQuote);
              });
            }
            if (this.filters.rutClient != '' && this.filters.rutClient != null) {
              this.cotizacionFiltro = this.cotizacionFiltro.filter(element => {
                return element.companyRut.includes(this.filters.rutClient);
              });
            }
            if (this.filters.nameClient != '' && this.filters.nameClient != null) {
              this.cotizacionFiltro = this.cotizacionFiltro.filter(element => {
                return element.companyName.includes(this.filters.nameClient);
              });
            }
            if (this.filters.stateQuote != '' && this.filters.stateQuote != null && this.filters.stateQuote.key) {
              this.cotizacionFiltro = this.cotizacionFiltro.filter(element => {
                return element.status.includes(this.filters.stateQuote.key);
              });
            }
            // console.log(this.cotizacionFiltro)
            if(this.cotizacionFiltro.length > 0){
              this.cotizaciones = this.cotizacionFiltro
            } else{
              swal({
                title: "¡Ups!",
                text: "No se han encontrado cotizaciones.",
                icon: "info",
                button: "Ok",
              })
              this.cotizaciones = this.cotizacionOriginal
              this.filters.numQuote = null
            }
          } else {
            swal({
              title: "¡Ups!",
              text: "Por favor, ingresa datos para buscar.",
              icon: "info",
              button: "Ok",
            })
          }
      } catch (error) {
          console.error('Error obteniendo cotizaciones:', error);
          throw error;
      }
    },
    async validate() {
      if (this.$refs.form.validate()) {
        // console.log("Success");
        this.login = 'Success'
        localStorage.setItem("Login",this.login)
        // this.$router.push({path:"/home"});
        window.location.href = "/home";
      }
    },
    cleanRadioButtons(){
        this.cotizaciones.forEach(item => {
            item.select = false;
        });
    },
    async envioCorreo(quote){
        await this.$recaptchaLoaded()
        const tokenPdf = await this.$recaptcha('getPdf')
        const tokenEmails2 = await this.$recaptcha('sendEmails2')
        try{
          // const userData = await ShieldService.getClientData(quote.person)
          // console.log('userdata: ',userData)
          // const userBirthdate = userData.birthdate.slice(0, 10);
          // const dataPoliza = await ShieldService.createPdf(quote._id,quote.counter,token) 

          const cotizacion = await ShieldService.getPdf(quote.counter,tokenPdf)

          // console.log(userBirthdate)
          // console.log(cotizacion)

          if (quote.insuranceAgentMail != '') {
            await ShieldService.sendEmail2(quote.insuranceAgentMail, quote.companyName, cotizacion.data.data, tokenEmails2)
          } else{
            await ShieldService.sendEmail2_2(quote.companyName, cotizacion.data.data, tokenEmails2)
          } 
          // await ShieldService.sendEmail3(quote.companyEmail, quote.companyName , quote.namePlan, quote.daysNumber, quote.newClient, userData.name, userData.rut, userBirthdate, userData.email, this.vigencia, this.fecha, tokenEmails3)
        } catch (error) {
          console.error('Error al enviar Correos:', error);
          throw error; 
        }
    },
    async sendEmails(items){
      // console.log('Emitir')
      this.sendingEmails = true
        try {
          //SEND EMAILS
          await Promise.all(items.map(async quote => {
            // console.log('quote: ', quote);
            await this.envioCorreo(quote);
          }));
          // console.log('paso envio de correos')
          await this.sending()
          this.cleanRadioButtons()
          if (this.role == 'Admin'){
            this.getAllQuotes()
          } else {
            this.getUserQuotes()
          }
          swal({
              title: "¡Excelente!",
              text: "Cotizaciones enviadas correctamente.",
              icon: "success",
              button: "Ok",
          })
          this.sendingEmails = false
        } catch (error) {
          console.error('Error en sendOut:', error);
          swal({
            title: "Error",
            text: "Ocurrió un error al enviar las cotizaciones.",
            icon: "error",
            button: "Ok",
          });
          this.sendingEmails = false
        }
    },
    async sendOut(){
        // console.log('Emitir')
        this.sendingOut = true
        const selectedItems = this.cotizaciones.filter(item => item.select);
        // console.log('selectedItems: ',selectedItems)
        if (selectedItems.length !== 0 ){        
            await this.sendEmails(selectedItems); 
            var arraySelectedItems = selectedItems.map(registro => registro._id )
            const response = await ShieldService.updateQuote(arraySelectedItems,'Generated');
            // console.log('response: ',response)
            if(response.status == 'ok'){
              this.cleanRadioButtons()
              if (this.role == 'Admin'){
                this.getAllQuotes()
              } else {
                this.getUserQuotes()
              }
              swal({
                title: "¡Excelente!",
                text: "Cotizaciones emitidas correctamente.",
                icon: "success",
                button: "Ok",
              })
            }
          this.sendingOut = false
        } else {
          swal({
            title: "¡Ups!",
            text: "Por favor, selecciona un registro para emitir.",
            icon: "info",
            button: "Ok",
          })
          this.sendingOut = false
        }
    },
    async sending(){
        // console.log('cambiando estado a enviados')
        const selectedItems = this.cotizaciones.filter(item => item.select);
        // console.log('selectedItems: ',selectedItems)
        if (selectedItems.length !== 0 ){
          var arraySelectedItems = selectedItems.map(registro => registro._id )
          const response = await ShieldService.updateQuote(arraySelectedItems,'Sent');
          console.log(response.status)
        } 
    },
    async validateChangeStatus(array){
      var arraySelectedItems = array.map(registro => registro.status );
      const existsPending = arraySelectedItems.includes('Pending');
      if (existsPending) {
        return true
      } else {
        return false
      }
    },
    async decline(){
        // console.log('Declinar')
        this.declineQuote = true
        const selectedItems = this.cotizaciones.filter(item => item.select);
        // console.log('selectedItems: ',selectedItems)
        if (selectedItems.length !== 0 ) {
            var arraySelectedItems = selectedItems.map(registro => registro._id )
            const response = await ShieldService.updateQuote(arraySelectedItems,'Rejected');
            // console.log('response: ',response)
            if(response.status == 'ok'){
              this.cleanRadioButtons()
              if (this.role == 'Admin'){
                this.getAllQuotes()
              } else {
                this.getUserQuotes()
              }
              swal({
                title: "¡Excelente!",
                text: "Cotizaciones declinadas correctamente.",
                icon: "success",
                button: "Ok",
              })
            }
         
          this.declineQuote = false
        } else {
          swal({
            title: "¡Ups!",
            text: "Por favor, selecciona un registro para declinar.",
            icon: "info",
            button: "Ok",
          })
          this.declineQuote = false
        }
    },
    async openDetail(item){
        // console.log('Ver detalle de:', item.counter) 
        await this.$recaptchaLoaded()
        const token = await this.$recaptcha('getPdf')
        this.downloadQuote = true
        try{
          const cotizacion = await ShieldService.getPdf(item.counter,token)
          // console.log('cotizacion: ',cotizacion)

          const byteCharacters = atob(cotizacion.data.data);
          const byteNumbers = new Array(byteCharacters.length);

          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }

          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: 'application/pdf' });

          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'cotizacion-travel.pdf');
          document.body.appendChild(link);

          link.click();
          this.downloadQuote = false

          // console.log('termine el proceso')

        } catch (error) {
          // console.log('error al descargar Pdf')
          swal({
            title: "Error",
            text: "Ocurrió un error al descargar la cotización.",
            icon: "error",
            button: "Ok",
          });
          this.downloadQuote = false
        }

        
    }
  },
};
</script>

<style>
.v-select-style-2{
  height: 50px;
  background: #ffffff;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
  width: 300px;
}
.filter-container{
  display: flex;
  flex-direction: column;
  background-color: yellow;
}
.contenedor-inicio{
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-sun {
    width: 21px;
    height: 21px; 
    flex-shrink: 0;
}
.saludo {
    color: var(--AD-Primario-2, #4F74E3);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 50px;
    letter-spacing: 0.667px;
    margin-top: 1.8%;
}
.buttonFilter{
    background-color: #2d387c !important;
    color: white !important;
    border-radius: 6px;
    margin-left: 1%;
    height: 50px !important;
    font-size: 24px !important;
    /* width: 80% !important; */
    /* height: 120% !important; */
}
.linea-negra {
  border: none;
  width: 100%;
  height: 5px;
  flex-shrink: 0;
  background: var(--Degradado-vibrante, linear-gradient(90deg, #A74CF2 8.2%, #658BFC 91.27%));
}
.buttonFilterRefresh{
    background-color: #2d387c !important;
    color: white !important;
    border-radius: 6px;
    margin-left: 1%;
    height: 50px !important;
    font-size: 24px !important;
    /* width: 80% !important; */
    /* height: 120% !important; */
}
.v-text-field-style-2{
  /* box-sizing: border-box; */
  height: 50px !important;
  width: 300px;
  /* background: yellow; */
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}
.form-container-2{
  /* background-color: yellowgreen; */
  display: flex;
  gap: 1%;
  width: 100% !important;
}
.tableHeaders {
  background-color: #2D387C;
  color: white !important;
  border: 2px solid #2D387C;
}

.table {
  border: 2px solid #2D387C;
  width: 100% !important;
}

.tableButton{
    color: white !important;
    background-color: #2D387C !important;
    margin-left: 1%;
    margin-bottom: 1%;
    margin-top: 1%;
}
.spinner-container {
  display: flex; 
  justify-content: center; 
  align-items: center;
  height: 100%;
  font-size: 300px;
}
.spinner {
  display: flex; 
  justify-content: center; 
  align-items: center; 
}
.spinner i {
  animation: spin 1s linear infinite; 
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); } 
}
.v-data-table__wrapper > table:nth-child(1) > tbody:nth-child(3) > tr:nth-child(1) > td:nth-child(1) > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) > div:nth-child() {
margin: auto;
}

</style>

<style lang="css">

.v-data-table>.v-data-table__wrapper>table>thead>tr>th {
  background-color: #2D387C;
  color: white !important;
}

</style>