<template>
  <v-container style="margin: auto">
    <NavBar :step="1" @retroceder="handlerRetrocederComponente"></NavBar>

    <Titulo></Titulo>

    <br />
    <br />

    <v-col>
      <v-stepper v-model="e1" elevation="0">
        <v-stepper-header style="box-shadow: none">
          <v-stepper-step :complete="e1 > 1" step="1"> Inicio </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="2"> Plan </v-stepper-step>

          <v-divider></v-divider>

          <!-- <v-stepper-step step="3"> Datos </v-stepper-step> -->

          <!-- <v-divider></v-divider> -->

          <v-stepper-step step="3"> Resumen </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="4"> Éxito </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="2">
            <div class="step-data">
              <v-form ref="form" v-model="valid" max-witdh="300px">
                <br />
                <h3 class="h3-text">Selecciona Comisión</h3>
                <h4 class="h4-text">
                  Selecciona tu porcentaje de comisión
                </h4>

               

                <!-- <v-select
                  class="v-select-style"
                  :rules="commissionRules"
                  :items="commissions"
                  v-model="dataQuote.commissionPercent"
                  :loading="loadingCommission"
                  @change="getPlans()"
                  label="Comisión"
                  return-object
                  required
                  solo
                  filled
                >
                </v-select> -->
                
                <div style=" width: 4%; border-radius: 5%; margin: 0 auto; text-align: center;">
                  <v-text-field style="text-align: center;"  v-model="dataQuote.commissionPercent" max="30" @change="getPlans()" @input="updateValue3"></v-text-field>
                </div>

                <v-slider track-color="4F74E3" class="slider" color="#4F74E3" min="0"  max="30" @change="getPlans()"  v-model="dataQuote.commissionPercent" :rules="commissionRules" :items="commissions"
                :loading="loadingCommission" @input="updateValue3"  return-object required solo filled></v-slider>

                <br><br><br>
                <h3 class="h3-text">Elije tu plan</h3>
                <h4 class="h4-text">
                  Te mostramos el detalle de los planes y beneficios que puedes contratar con nosotros:
                </h4>

              
              </v-form>
            </div>

            <br />
            <br />

            <div
              
              class="plan-container"
            >
              <PlanEscritorio
                :planData="plans"
                :tipoPlan="dataQuote.planType"
                :loadDollars="loadDollars"
                :loadingPlans="loadingPlans"
                @next-step="validate"
                @changeComponent="handlerChangeComponent"
              />
            </div>

            <div
              v-if="
                screen > 800 &&
                loadDollars == false &&
                loadingPlans == false &&
                tipoPlan == true
              "
              class="plan-container"
            >
              <PlanEscritorio
                v-for="(plan, indice) in planSenior"
                :key="plan._id"
                :planData="plan"
                :index="indice"
                :comission="dataQuote.commissionPercent"
                :tipoPlan="tipoPlan"
                @next-step="validate"
                @changeComponent="handlerChangeComponent"
              />
            </div>

            <div
              v-if="
                screen <= 800 &&
                loadDollars == false &&
                loadingPlans == false &&
                tipoPlan == false
              "
              class="plan-container"
            >
              <PlanEscritorio
                v-for="(plan, indice) in plans"
                :planSenior="planSenior"
                :key="plan._id"
                :planData="plan"
                :index="indice"
                :comission="dataQuote.commissionPercent"
                :tipoPlan="tipoPlan"
                @next-step="validate"
                @changeComponent="handlerChangeComponent"
              />
            </div>

            <div
              v-if="
                screen <= 800 &&
                loadDollars == false &&
                loadingPlans == false &&
                tipoPlan == true
              "
              class="plan-container"
            >
              <PlanEscritorio
                v-for="(plan, indice) in planSenior"
                :key="plan._id"
                :planData="plan"
                :index="indice"
                :comission="dataQuote.commissionPercent"
                :tipoPlan="tipoPlan"
                @next-step="validate"
                @changeComponent="handlerChangeComponent"
              />
            </div>

            <!-- <div v-if="screen <= 800 && loadingPlans == false" class="plan-container">
              <PlanMovil :planData="plans" :planSenior="planSenior" :tipoPlan="tipoPlan" @next-step="validate" />
            </div> -->
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-col>
  </v-container>
</template>

<script>
import PlanEscritorio from "@/components/PlanEscritorio.vue";
// import PlanMovil from "@/components/PlanMovil.vue";
import NavBar from "@/components/NavBar.vue";
import ShieldService from "../services/shield.service";
import Titulo from "../components/Titulo.vue";
import CommissionService from "@/services/commission.service.js";

export default {
  name: "PlanForm",
  components: { PlanEscritorio, NavBar, Titulo },
  data: () => ({
    plans: [],
    loadDollars: false,
    planSenior: [],
    dollarPrice: null,
    e1: 2,
    loadingPlans: true,
    screen: window.screen.width,
    dataQuote: {
      planId: null,
      commissionPercent: null,
      daysNumber: null,
      planType: null,
    },
    commissions: [],
    tipoPlan: false,
    // commissionRules: [(v) => !!v || "La comisión es requerida"],
  }),
  async mounted() {
    if (this.screen > 800) {
      this.labelDescuento = "labelDescuento";
    } else {
      this.labelDescuento = "labelDescuento2";
    }
    this.dataQuote.planType = localStorage.getItem("planType");
    this.dataQuote.daysNumber = localStorage.getItem("daysNumber");
    this.dataQuote.commissionPercent = localStorage.getItem("commissionPercent")
    this.getCommission();
    this.getPlans();
  },
  methods: {
    handlerRetrocederComponente(step){
      // console.log('step: ',step)
      this.$emit('cambiar-componente','back'+step);
    },
    handlerChangeComponent(data) {
      if (data === 'planes') {
        this.$emit('cambiar-componente','planes');
      }
    },
    validate(plan) {
      this.$emit("next-step", 2, plan);
      // this.$emit('cambiar-componente','planes',plan);

    },
    async getPlans() {
      this.loadDollars = true;
      this.dollarPrice = await ShieldService.getDollar();
      this.dollarPrice = this.dollarPrice.data;
      // console.log("dollar : " + this.dollarPrice);
      localStorage.setItem("dollar", this.dollarPrice);

      localStorage.setItem(
        "commissionPercent",
        this.dataQuote.commissionPercent
      );
      this.loadingPlans = true;
      this.plans = await ShieldService.getPlans(
        this.dataQuote.daysNumber,
        this.dataQuote.planType
      );
      // console.log("Estos son los planes", this.plans);
      var aux = [null,null,null]

      this.plans.forEach((plan) => {
        var newPlan = this.recalculatePrice(plan);
        console.log(newPlan);

        // console.log("PLAN" + plan.name)
       
       if(plan.name == 'Plan Silver Plus INT'){
        aux[0] = plan
       } else if(plan.name == 'Plan Gold Plus INT'){
        aux[1] = plan
       } else if(plan.name == 'Plan Platinum Plus INT'){
        aux[2] = plan
       }  else if(plan.name == 'Plan Silver Plus INT/NAC'){
        aux[0] = plan
       } else if(plan.name == 'Plan Gold Plus INT/NAC'){
        aux[1] = plan
       } else if(plan.name == 'Plan Platinum Plus INT/NAC'){
        aux[2] = plan
       } 
      });
      // console.log("aux" +aux[0].name)
      // console.log("aux" +aux[1].name)
      // console.log("aux" +aux[2].name)
      this.plans = aux;
      
      this.loadDollars = false;
      this.loadingPlans = false;
    },
    async getCommission() {
      this.loadingCommission = true;
      this.commissions = await CommissionService.getCommission();
      this.loadingCommission = false;
    },
    async recalculatePrice(plan) {
      // console.log("comision : " + this.dataQuote.commissionPercent);

      if (this.dataQuote.commissionPercent) {
        var commission = this.dataQuote.commissionPercent;
        commission = parseInt(commission) / 100;
        var auxPrice = plan.price * this.dataQuote.daysNumber;
        // plan.newPrice = (auxPrice + auxPrice * commission).toFixed(2);
        plan.newPrice = ((auxPrice)/(1-commission-0.46)).toFixed(2)
        // var auxPrice = plan.price * this.dollarPrice * this.dataQuote.daysNumber;
        // plan.newPrice = ((auxPrice) + (auxPrice * commission)).toFixed(0);
        // ("newprice"+ plan.newPrice);
      } else {
        plan.newPrice = ((plan.price * this.dataQuote.daysNumber)/(1-0.46)).toFixed(2);
       
        // plan.newPrice = (plan.price * this.dollarPrice * this.dataQuote.daysNumber).toFixed(0);
        // console.log("newprice"+ plan.newPrice);
      }
      return plan;
    },
    updateValue3() {
      if (this.valorUf2 > 20000) {
        this.valorUf2 = 20000;
      }
    },
  },
};
</script>

<style lang="scss">
.plan-container {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

.labelDescuento {
  width: 320px;
  height: 48px;
  background: #ffffff;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 10px 0 0 10px;
  margin: auto;
}

.labelDescuento2 {
  min-width: 30%;
  max-width: 62%;
  height: 48px;
  background: #ffffff;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 10px 0 0 10px;
  margin: auto;
}

.titulo {
  background-image: url("~@/assets/images/titulo2.png");
  max-width: 350px;
  height: 150px;
  margin: auto;
}

.h3-text {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 15px;
  letter-spacing: -0.01em;
  color: #075596;
}
.h4-text {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #232323;
  opacity: 0.75;
  padding: 2%;
}
.v-select-style {
  height: 57px;
  background: #ffffff;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}

.slider {
  display: flex;
  width: 1139px;
  height: 7px;
  padding: 10px;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
  padding-right: 25%;
  padding-left: 25%;
  border-radius: 10px;
  margin-top: -2%
}
</style>
