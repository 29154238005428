<template>
  <v-app>
      <!-- <NavBar /> -->
    <v-main>
      <router-view />
    </v-main>
    <!-- <Footer v-if="currentPath != '/' " /> -->
  </v-app>
</template>

<script>
// import Footer from "@/components/Footer.vue";
import Walmart from "@/assets/images/Intro.png";

export default {
  components: {
    // Footer
  },
  data: () => ({
    Walmart,
    currentPath: null,
  }),
  mounted() {
    this.currentPath = this.$route.path;
    // console.log(this.currentPath)
  }

};
</script>