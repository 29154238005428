<template>
  <v-container>
    <NavBar :step="0" />
    <Titulo />
    <br />
    <br />

    <v-stepper v-model="e1" elevation="0">
      <v-stepper-items>
        <v-stepper-content step="1">
          <div class="step-data">
            <v-form ref="form" v-model="valid" max-width="300px">
              <h3 class="h3-text">Añadir Nuevo Usuario</h3>
              <br />

              <div>
                <v-text-field class="v-text-field-style" v-model="userData.name" :rules="nameRules" label="Nombre"
                  required solo filled></v-text-field>
                <br />
                <v-text-field class="v-text-field-style" v-model="userData.rut" :rules="rutRules" label="Rut" @input="changeRut"
                  required solo filled ></v-text-field>
                <br />
                <v-text-field class="v-text-field-style" v-model="userData.phoneNumber" :rules="phoneRules"
                  label="Teléfono" prefix="+56" maxlength="9" required solo filled></v-text-field>
                <br />
                <v-text-field class="v-text-field-style" v-model="userData.email" :rules="emailRules" label="Email"
                  required solo filled></v-text-field>
                <br />
                <v-text-field class="v-text-field-style" v-model="userData.password" :rules="formPasswordRules"
                  label="Contraseña" name="company-password" autocomplete="new-password" type="password" required solo
                  filled></v-text-field>
                <br />
                <v-select class="v-select-style" :items="companies" v-model="userData.company" :rules="companiesRules"
                    item-text="name" item-value="companySponsor" label="Empresa" solo filled required >
                </v-select>
              </div>

              <br /><br /><br />

              <div class="button-container">
                <v-btn class="buttons" @click="validate">Añadir Usuario</v-btn>
              </div>
            </v-form>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import RutUtils from "@/utils/rut.utils.js";
import swal from 'sweetalert';
import ShieldService from '../services/shield.service';
import Vue from "vue";
import { VueReCaptcha } from 'vue-recaptcha-v3'
import CompaniesService from "../services/companies.service";
const { VUE_APP_RECAPTCHA3_SITE_KEY } = process.env;

Vue.use(VueReCaptcha, { siteKey: VUE_APP_RECAPTCHA3_SITE_KEY })

export default {
  name: "addUser",
  components: { NavBar },
  data: () => ({
    e1: 1,
    newRut: null,
    userData: {
      name: null,
      rut: null,
      phoneNumber: null,
      email: null,
      password: null,
      company: null,
    },
    valid: false,
    companies: [],
    nameRules: [
      (v) => !!v || "El nombre es requerido",
      (v) => /^[\p{L}\s-]+$/u.test(v) || "Ingrese solo letras",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 carácteres",
    ],
    companiesRules: [
      (v) => !!v || "Ingresa la region en la que vives con tu mascota",
    ],  
    rutRules:[
      (v) => !!v || "El RUT es requerido",
      (v) => RutUtils.validateRut(v) || "Rut no válido",
    ],

    phoneRules: [
      (v) => !!v || "El número telefónico es requerido",
      (v) => /^[0-9]*$/.test(v) || "Ingrese solo números",
    ],
    
    emailRules: [
      (v) => !!v || "El correo electrónico es requerido",
      (v) => /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(v) || "Ingrese un correo válido",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 caracteres" 
    ],
    formPasswordRules: [(v) => !!v || "La contraseña es requerida"],
  }),
  mounted() {
    this.getCompanies();
  },
  methods: {
    async validate() {
      if (this.$refs.form.validate()) {
        localStorage.setItem("name", this.userData.name);
        localStorage.setItem("rut", this.userData.rut);
        localStorage.setItem("phoneNumber", this.userData.phoneNumber);
        localStorage.setItem("email", this.userData.email);
        localStorage.setItem("password", this.userData.password);
        localStorage.setItem("company", this.userData.company);

        const response = await this.createUser();
        console.log('respuesta', response.status)
        switch (response.status) {
          case 'registered':
            swal({
              title: "Atención!",
              text: "El usuario ya se encuentra registrado",
              icon: "info",
              button: "De acuerdo",
            });
            
            break;

          case 'ok':
            swal({
              title: "¡Excelente!",
                text: "Usuario creado exitosamente",
                icon: "success",
                button: "Ok",
            });
            this.$emit('cambiar-componente', 'users');
            break;

          case 'error':
            swal({
              title: "Error!",
              text: "Ocurrió un error al crear usuario. Por favor, vuelve a intentarlo.",
              icon: "error",
              button: "Ok",
            });

            break;
        
          default:
            break;
        }
      }
    },
    changeRut(rut) {
      this.userData.rut = RutUtils.cleanRut(rut);
      this.newRut = RutUtils.formatRut(rut);
      this.userData.rut = this.newRut;
    },
    async getCompanies() {
      this.companies = await CompaniesService.getCompanies();
    },
    async createUser(){
        await this.$recaptchaLoaded()
        const token = await this.$recaptcha('createUser')
        try {
          const response = await ShieldService.createUser(this.userData.name, this.userData.rut, this.userData.phoneNumber, this.userData.email, this.userData.password, this.userData.company , token);
          return response;
      } catch (error) {
          console.error('Error creando usuario:', error);
          throw error;
      }
    },
  },
};
</script>

<style scoped>
.h3-text {
  font-family: Helvetica;
  font-weight: 700;
  font-size: 18px;
  color: #2d387c;
}

.step-data {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttons {
  background-color: #2d387c !important;
  color: white !important;
}
.v-select-style{
  height: 57px;
  background: #ffffff;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}
</style>