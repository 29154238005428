<template>
  <div>
    

     <!-- <div >
      <label>
      {{ planData[4]}}
    </label> <
    
    </div>  -->

    <table >
      <thead>
        <tr >
            <th></th>
            <th > SILVER <br><br> 
              $USD {{ formatNumber(planData[0].newPrice) }} <br>
              <v-btn class="contratar-buttons" @click="validate(0)">Contratar</v-btn> 
            </th>
            <th>GOLD <br><br> 
              $USD {{ formatNumber(planData[1].newPrice)}} <br>
              <v-btn class="contratar-buttons" @click="validate(1)">Contratar</v-btn> 
              
            </th>
            <th >PLATINUM <br><br>
              $USD {{ formatNumber(planData[2].newPrice)}} <br>
              <v-btn class="contratar-buttons" @click="validate(2)">Contratar</v-btn>
            </th>
        </tr>
        
        <tr>
            <th>BENEFICIOS</th>
            <th>AC 250 USD</th>
            <th> AC 500 USD</th>
            <th> AC 1M USD</th>
        </tr>
      </thead>
      <tbody>
        <tr>
                <td>Muerte Accidental**</td>
                <td>125.000</td>
                <td>250.000</td>
                <td>500.000</td>
            </tr>
            <tr>
                <td>Incapacidad total y permanente**</td>
                <td>125.000</td>
                <td>250.000</td>
                <td>500.000</td>
            </tr>
            <tr>
                <td>Indemnización por pérdida de equipaje</td>
                <td>1.200</td>
                <td>1.200</td>
                <td>1.200</td>
            </tr>
            <tr>
                <td>Compensación por demora en la entrega del equipaje no localizado (más de 6hs)</td>
                <td>200</td>
                <td>200</td>
                <td>200</td>
            </tr>
            <tr>
                <td>Compensación por demora en la entrega del equipaje no localizado (adicionales, más de 10 días)</td>
                <td>1.000</td>
                <td>1.000</td>
                <td>1.000</td>
            </tr>
            <tr>
                <td>Monto máximo Global</td>
                <td>250.000</td>
                <td>500.000</td>
                <td>1.000.000</td>
            </tr>
            <tr>
                <td>Odontología de urgencia</td>
                <td>800</td>
                <td>800</td>
                <td>1.000</td>
            </tr>
            <tr>
                <td>Medicamentos ambulatorios</td>
                <td>2.500</td>
                <td>2.500</td>
                <td>3.000</td>
            </tr>
            <tr>
                <td>Medicamentos en caso de hospitalización</td>
                <td>Incluido</td>
                <td>Incluido</td>
                <td>Incluido</td>
            </tr>
            <tr>
                <td>Regreso de Acompañante del titular repatriado</td>
                <td>Incluido</td>
                <td>Incluido</td>
                <td>Incluido</td>
            </tr>
            <tr>
                <td>Traslado de un familiar</td>
                <td>Incluido</td>
                <td>Incluido</td>
                <td>Incluido</td>
            </tr>
            <tr>
                <td>Estancia de un familiar (Por día USD 90)</td>
                <td>600</td>
                <td>600</td>
                <td>750</td>
            </tr>
            <tr>
                <td>Regreso anticipado por siniestro grave en el domicilio</td>
                <td>Incluido</td>
                <td>Incluido</td>
                <td>Incluido</td>
            </tr>
            <tr>
                <td>Telemedicina - Consultas médicas online</td>
                <td>Incluido</td>
                <td>Incluido</td>
                <td>Incluido</td>
            </tr>
            <tr>
                <td>Traslado de ejecutivos por emergencia</td>
                <td>Incluido</td>
                <td>Incluido</td>
                <td>Incluido</td>
            </tr>
      </tbody>
      <br>
      <v-btn class="buttons" @click="verMasChange()" v-if="verMas==false" style="margin-left: 80%;"> Ver más detalles</v-btn>
      <v-btn class="buttons" @click="verMasChange()" v-if="verMas==true" style="margin-left: 80%;"> Ver menos detalles</v-btn>
      <br><br>
      <tbody v-if="verMas==true">
        <tr>
            <td>Asistencia Médica en caso de Accidente o Enfermedad no preexistente (incluido COVID-19)</td>
            <td>250.000</td>
            <td>500.000</td>
            <td>1.000.000</td>
        </tr>
        <tr>
            <td>Asistencia Médica en caso de Enfermedad preexistente Anuales*</td>
            <td>3.000</td>
            <td>3.000</td>
            <td>5.000</td>
        </tr>
        <tr>
            <td>Asistencia Médica en caso de Enfermedad preexistente Diarias*</td>
            <td>1.500</td>
            <td>1.500</td>
            <td>2.500</td>
        </tr>
        <tr>
            <td>Práctica de deportes *</td>
            <td>150</td>
            <td>150</td>
            <td>150</td>
        </tr>
        <tr>
          <td>Estado de embarazo *</td>
          <td>10.000</td>
          <td>10.000</td>
          <td>10.000</td>
      </tr>
      <tr>
          <td>Prótesis y ortesis</td>
          <td>5.000</td>
          <td>5.000</td>
          <td>10.000</td>
      </tr>
      <tr>
          <td>Traslados Sanitarios</td>
          <td>Incluido</td>
          <td>Incluido</td>
          <td>Incluido</td>
      </tr>
      <tr>
          <td>Repatriaciones (Sanitaria o funeraria)</td>
          <td>100.000</td>
          <td>100.000</td>
          <td>100.000</td>
      </tr>
      <tr>
          <td>Gastos de Hotel por reposo forzoso luego de una hospitalización (Por día USD 180)</td>
          <td>1.100</td>
          <td>1.100</td>
          <td>1.500</td>
      </tr>
      <tr>
          <td>Diferencia de tarifa por viaje de regreso retrasado o anticipado</td>
          <td>Incluido</td>
          <td>Incluido</td>
          <td>Incluido</td>
      </tr>
      <tr>
          <td>Asistencia legal por responsabilidad en un accidente (hasta)</td>
          <td>10.000</td>
          <td>10.000</td>
          <td>10.000</td>
      </tr>
      <tr>
          <td>Anticipo de fondos para fianzas (Préstamo)</td>
          <td>50.000</td>
          <td>50.000</td>
          <td>25.000</td>
      </tr>
      <tr>
          <td>Asistencia en caso de robo o extravío de documentos, etc</td>
          <td>Incluido</td>
          <td>Incluido</td>
          <td>Incluido</td>
      </tr>
      <tr>
          <td>Localización de equipajes</td>
          <td>Incluido</td>
          <td>Incluido</td>
          <td>Incluido</td>
      </tr>
      <tr>
          <td>Penalidad por cancelación de ticket aéreo (Por evento, hasta)</td>
          <td>100</td>
          <td>100</td>
          <td>100</td>
      </tr>
      <tr>
          <td>Safe Passport</td>
          <td>150</td>
          <td>150</td>
          <td>150</td>
      </tr>
      </tbody>
    </table>
    

  </div>
</template>

<script>
import LibreEleccion from "../assets/images/libreeleccion.png"
import swal from 'sweetalert';

import ShieldService from '../services/shield.service';
// import { parse } from "path";

export default {
  name: "Plan",
  props: ["planData", "planSenior", "index", "comission", "tipoPlan"],
  data: () => ({
    namePlan:null,
    verMas: false,
    finalPlanData: null,
    stepId: null,
    daysNumber: null,
    // priceUSD: null,
    dollar: null,
    counter: null,
    formatter: new Intl.NumberFormat("es-CL", {
      style: "currency",
      currency: "CLP",
    }),
    LibreEleccion,
    info: [['Plan Lite', 'Plan Básico', 'Plan Plus', 'Plan Senior'], ['40%', '60%', '80%', '50%'], ['50', '100', '250', '20'], ['2', '2', '3', '3'], ['1', '2', '3', '3'], ['1', '1', '2', '4'], ['3', '4', '6', '6'], ['2', '2', '4', '4'], ['3', '10', '15', '8'], ['15', '15', '15', '30']],
    indexInfo: '0',
    dialog: false,
  }),
  clienteNuevo: null,
  dataQuote:{
      daysNumber: null,
      commissionPercent: null,
      insuranceAgentMail: '',
      contractingPartyMail: '',
      priceCLP: null,
      priceUSD: null,
      price: null,
      planId: null,
      planType: null,
      // counter: null,
    },
    userData: {
      name: null,
      rut: null,
      email: null,
      dates: null,
      address: null,
      comune:null,
      phoneNumber: null
    },
    companyData:{
      name:null,
      rut:null,
      email: null,
      address:null,
      comune:null
    },
  methods: {
    formatNumber(num) {
      num = parseFloat(num)
      if (num > 1000) {
        console.log(num.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))
        return num.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      }
      return num.toString();
      
    },
    Info(index) {
      if (this.planData.name == 'Plan Senior') {
        this.indexInfo = 3
      } else {
        this.indexInfo = index
      }
      this.dialog = true
    },
    formatPrice(number) {
      return this.formatter.format(number);
    },
    ufchange(uf) {
      let nn = uf.toString();
      let new_uf = nn.replace('.', ',')
      return new_uf;
    },
    verMasChange(){
      if(this.verMas == false){
        this.verMas = true
      } else{
        this.verMas = false
      }
    },
    async validate(index) {
      await this.$recaptchaLoaded()
      const token = await this.$recaptcha('addCounter')
      if (this.comission != 'null' ){
        localStorage.setItem("planId", this.planData[index]._id);
        localStorage.setItem("priceCLP",this.planData[index].newPrice)
        localStorage.setItem("namePlan",this.planData[index].name)
        
        
        this.counter = await ShieldService.addCounter(token)
        this.counter = this.counter.policyCounter
        localStorage.setItem("counter", this.counter)
        // console.log("este es el counter: " + this.counter)
        // console.log(this.dataQuote.daysNumber)
        // console.log()
        // var insurance = await ShieldService.createInsurance(this.dataQuote, this.userData, this.companyData, this.counter);
        // console.log(insurance)


        //USD CALCULATE
        // this.priceUSD = (this.planData[index].newPrice / this.dollar).toFixed(2)
        // localStorage.setItem("priceUSD",this.priceUSD);
        // console.log('intentando validar')
        // this.$router.push({path:"/resumenConfirmacion"});
        this.$emit('changeComponent','planes');

        // window.location.pathname = "/datos";
        
      }else{
        // console.log("hola");
        swal("Porfavor, selecciona una Comisión", "", "error");
        
      } 
    },
    
  },
  async mounted() {

    this.stepId = localStorage.getItem('stepId')
    this.comission = localStorage.getItem('commissionPercent')
    this.daysNumber = localStorage.getItem('daysNumber')
    this.dollar = localStorage.getItem('dollar')
    // console.log('DESKTOP')
    // console.log("plan Data:," + this.planData);

    this.planData.then((resultado) => {
      this.finalPlanData = resultado
    });
    
  },
};
</script>

<style lang="scss" scoped>
.texto {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: rgba(0, 0, 0, 0.5);
  flex: none;
  order: 1;
  flex-grow: 0;
}

.textoAzul {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400px;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: #2D387C;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.encabezado {
  color: #2D387C;
  font-size: 18px;
  font-weight: 600;
}

.plan-info {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.plan {
  background-color: #ffffff;
  background-image: url("~@/assets/images/fondoPlanRecomendaado2.png");
  background-position: center;
  background-size: cover;
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0.5rem;
  align-items: center;
  padding: 0.75rem;
  height: 680px;
}

// .plan2 {
//   background-color: #ffffff;
//   background-image: url("~@/assets/images/planNormal.png");
//   background-position: center;
//   background-size: cover;
//   width: 350px;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   padding: 0.75rem;
//   margin-top: 15%;
//   height: 590px;
// }
.plan2 {
  border: 1px solid grey;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0.5rem;
  align-items: center;
  padding: 0.75rem;
  border-radius: 10px 10px 10px 10px;
  width: 270px;
  margin-top: 5%;
}

.plan-button{
  background-color: #2D387C !important;
  color: white; 
  border-radius: 12px; 
  width: 135% !important;
}
.plan-title{
  color: #2D387C; 
  font-size:32px;
  height: 90px ;
}

.buttons{
    background-color: #2d387c !important;
    color: white !important;
    border-radius: 12px;
  }
  .contratar-buttons{
    background-color: #EDB321 !important;
    color: white !important;
    width: 80%;
    margin-top: 10%;
  }
.li-style{
  font-family: Helvetica; 
  font-style: normal; 
  font-weight: 400; 
  font-size: 14px; 
  line-height: 140%; 
  letter-spacing: 0.02em;
  color: rgba(0, 0, 0, 0.5); 
  flex: none; order: 1; 
  flex-grow: 0; 
  text-align: left; 
  margin-left: 10%;
  text-align: justify;
  margin-bottom: 6%;
  text-decoration: none;
}
.hr-style{
  margin: auto;
  border: 1px solid rgba(0, 0, 0, 0.3); 
  flex: none; 
  order: 1; 
  flex-grow: 0; 
  width: 240px; 
  height: 0px;
}

table {
 width: 100%;
 border-collapse: collapse;
}

th, td {
 padding: 10px;
 text-align: left;
 border-style: none;
}

th {
 background-color: #f2f2f2;
 font-weight: bold;
}

tr:nth-child(even) {
 background-color: #f2f2f2;
}

tr:hover {
 background-color: #ddd;
}

.ac {
 color: #e02a2c;
}

.usd {
 color: #008c3a;
}
</style>