export default [
    {
        name: "AGENCIAS BRINER CORREDORES DE SEGUROS S.A.",
        companySponsor: "briner-corredores"
    },
    {
        name: "ALICIA DEL CARMEN PASTOR ARANEDA",
        companySponsor: "alicia-del-carmen"
    },
    {
        name: "ALTUM CORREDORES DE SEGUROS LIMITADA",
        companySponsor: "altum"
    },
    {
        name: "ANDRES SCHMIDT Y COMPAÑIA LIMITADA",
        companySponsor: "andres-schmidt-y-compañia"
    },
    {
        name: "AON RISK SERVICES CHILE",
        companySponsor: "aon-risk-services"
    },
    {
        name: "ARTHUR J GALLAGHER CORREDORES DE SEGUROS SA",
        companySponsor: "arthur-j-gallagher"
    },
    {
        name: "ASESORIAS EMILIO SOTOMAYOR Y ASOCIADOS",
        companySponsor: "asesorias-emilio"
    },
    {
        name: "BALDRICH PRODUCTORES Y CORREDORES DE SEGUROS",
        companySponsor: "baldrich"
    },
    {
        name: "BANCHILE CORREDORES DE SEGUROS LIMITADA",
        companySponsor: "banchile"
    },
    {
        name: "CONO SUR CORREDORES DE SEGUROS LIMITADA",
        companySponsor: "cono-sur"
    },
    {
        name: "GABRIEL ALFARO Y COMPAÑIA LIMITADA",
        companySponsor: "gabriel-alfaro-y-compañia"
    },
    {
        name: "GLOBAL INSURANCE CORREDORES DE SEGUROS SPA",
        companySponsor: "global-insurance"
    },
    {
        name: "HR CORREDORES DE SEGUROS SPA",
        companySponsor: "hr"
    },
    {
        name: "INSUREX CORREDORES DE SEGUROS LIMITADA",
        companySponsor: "insurex"
    },
    {
        name: "LOCKTON CHILE CORREDORES DE SEGUROS SPA",
        companySponsor: "lockton-chile"
    },
    {
        name: "LUCAS MARCHANT MORALES",
        companySponsor: "lucas-marchant"
    },
    {
        name: "MARGARITA MARIA LABBE CARRASCO",
        companySponsor: "margarita-labbe"
    },
    {
        name: "MARSH S.A. CORREDORES DE SEGUROS",
        companySponsor: "marsh"
    },
    {
        name: "MERCER",
        companySponsor: "mercer"
    },
    {
        name: "ORON CORREDORES DE SEGUROS LIMITADA",
        companySponsor: "oron"
    },
    {
        name: "OSCAR ALFREDO BEYTIA ROSENDE",
        companySponsor: "oscar-beytia"
    },
    {
        name: "PROA SEGUROS LIMITADA",
        companySponsor: "proa-seguros"
    },
    {
        name: "ROBINSON MARCELO MORA CARDENAS",
        companySponsor: "robinson-mora"
    },
    {
        name: "ROMINA SEPULVEDA",
        companySponsor: "romina-sepulveda"
    },
    {
        name: "RSG RISK SOLUTIONS GROUP CHILE",
        companySponsor: "rsg-risk-solutions"
    },
    {
        name: "SEGUMONT CORREDORES DE SEGUROS LIMITADA",
        companySponsor: "segumont"
    },
    {
        name: "SERGIO POBLETE CORREDORES DE SEGUROS SPA",
        companySponsor: "sergio-poblete"
    },
    {
        name: "SOC DE CORREDORES DE SEGUROS ZEPEDA Y COLINA",
        companySponsor: "soc-corredores"
    },
    {
        name: "SOUTHBRIDGE VENTA DIRECTA",
        companySponsor: "directo"
    },
    {
        name: "TEST",
        companySponsor: "test"
    },
    {
        name: "THB CHILE CORREDORES DE SEGUROS SA",
        companySponsor: "thb-chile"
    },
    {
        name: "VOLVEK CORREDORES DE SEGUROS SOCIEDAD ANONIMA",
        companySponsor: "volvek"
    },
    {
        name: "WIENECKE CORREDORES DE SEGUROS LIMITADA",
        companySponsor: "wienecke"
    },
    {
        name: "WILLIAMS Y COMPAÑIA CORREDORES DE SEGUROS SPA",
        companySponsor: "williams-y-compañia"
    },
    {
        name: "WILLIS TOWERS WATSON S.A.",
        companySponsor: "willis-watson"
    },
];
