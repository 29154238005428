export default class planService {
  
    static async getPlans() {
      try {
        var plans = [];
        // plans.push("País internacional")
        // plans.push("Chile y/o País internacional")
        plans.push("Nacional/Internacional")
        plans.push("Internacional")
        
        // plans.push("Ambas")

        return plans;
      } catch (error) {
        return {
          status: "error",
          message: "Ocurrio un error al obtener los Planes",
        };
      }
    }
  }