<template>
  <v-container>
    <NavBar :step="0" />

    <Titulo></Titulo>

    <br />
    <br />

    <v-stepper v-model="e1" elevation="0">
      <v-stepper-header style="box-shadow: none">
        <v-stepper-step :complete="e1 > 1" step="1"> Inicio </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 2" step="2"> Plan </v-stepper-step>

        <v-divider></v-divider>

        <!-- <v-stepper-step :complete="e1 > 3" step="3"> Datos </v-stepper-step>

        <v-divider></v-divider> -->

        <v-stepper-step :complete="e1 > 3" step="3">
          Resumen
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="4"> Éxito </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <div class="step-data">
            <v-form ref="form" v-model="valid" max-witdh="300px">
              <br /><br />
              <h3 class="h3-text">
                Selecciona el plan
              </h3>
              <h4 class="h4-text">
                ¿Quieres coberturas nacionales, internacionales o ambas?
              </h4>

              <v-select class="v-select-style"  :rules="planRules" :items="plans" v-model="dataQuote.planType" 
                v-on:change="getPlans()" label="Selecciona el Plan" return-object required
              solo filled></v-select>

              <br />
              <br />

              <h3
                class="h3-text">
                Cantidad de días
              </h3>

              <h4 class="h4-text">
                ¿Cuantos días quieres comprar?
              </h4>

              <!-- <v-select class="v-select-style" :rules="daysRules" :items="days" v-model="dataQuote.daysNumber" :loading="loadingDays"
                v-on:change="getDays()" label="Cantidad de días" return-object required solo filled>
              </v-select> -->
              <v-text-field class="v-text-field-style" v-model="dataQuote.daysNumber" :rules="daysRules" label="Cantidad de Días" required solo filled></v-text-field>


              <br />
              <br />

              <h3 class="h3-text">¿Es Empresa Nueva?</h3>
              <h4 class="h4-text">
                Ingrese los datos de la empresa que va a cotizar
              </h4>

              <v-radio-group v-model="newCompany" @change="changeEsNuevoCompany()" style="margin-left: 35%; margin-right: 25%;" row>
                <v-radio style="margin-right: 25%;" label="Si" value="si"></v-radio>
                <v-radio label="No" value="no"></v-radio>
              </v-radio-group>

              <div v-if="newCompany == 'si'">
                <v-text-field class="v-text-field-style" v-model="companyData.name" :rules="nameRules" label="Nombre" required solo filled></v-text-field>
                <br/>
                <v-text-field class="v-text-field-style" v-model="companyData.rut" :rules="rutRules" label="Rut" @input="changeRut" required solo filled></v-text-field>
                <br/>
                <v-text-field class="v-text-field-style" v-model="companyData.address"  :rules="addressRules" label="Dirección" required solo filled></v-text-field>
                <br/>
                <v-text-field class="v-text-field-style" v-model="companyData.comune" :rules="comuneRules" label="Comuna" required solo filled></v-text-field>
                <br/>
                <v-text-field class="v-text-field-style" v-model="companyData.phoneNumber" :rules="phoneRules" label="Teléfono" 
                  prefix="+56" :counter="9" maxlength="9"  required solo filled></v-text-field>
                <br/>
                <v-text-field class="v-text-field-style" v-model="companyData.email" :rules="emailRules" label="Correo" required solo filled></v-text-field>
              </div>  

              <div v-if="newCompany == 'no'">
                <v-select class="v-select-style"  :items="userCompanies" v-model="companyId" 
                  label="Selecciona una Empresa" return-object required
                  solo filled no-data-text="No hay Empresas Disponibles" item-text="name" ></v-select>
              </div>

              <br/><br><br>
              <h3 class="h3-text">¿Es cliente Nuevo?</h3>
              <h4 class="h4-text">
                Ingrese los datos de la persona que va a administrar la bolsa de días de la empresa
              </h4>

              <v-radio-group v-model="clienteNuevo" @change="changeEsNuevoCliente()" style="margin-left: 35%; margin-right: 25%;" row>
                <v-radio style="margin-right: 25%;" label="Si" value="si"></v-radio>
                <v-radio label="No" value="no"></v-radio>
              </v-radio-group>
              <v-messages :value="clienteNuevo" :error-messages="clienteNuevoError"></v-messages>



              
              <div v-if="clienteNuevo == 'si'"> 
                <v-text-field class="v-text-field-style" v-model="userData.name" :rules="nameRules2" label="Nombre" required solo filled></v-text-field>
              <br>
              <v-text-field class="v-text-field-style" v-model="userData.rut" :rules="rutRules2" label="Rut" @input="changeRut2" required solo filled></v-text-field>
              <br>
             

              <v-row >

                <v-col>
                  <v-select :rules="monthRules" style="max-width: 130px; height: 57px; background: #FFFFFF; border: 1.5px solid rgba(142, 142, 142, 0.2); box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
                    border-radius: 6px;" :items="months" v-model="month" :loading="loadingMonths"
                    v-on:change="getDays2()" item-text="name" label="Mes" return-object required solo
                    filled></v-select>
                </v-col>

                <v-col>
                  <v-select :rules="dayRules" style="max-width: 130px; height: 57px; background: #FFFFFF; border: 1.5px solid rgba(142, 142, 142, 0.2); box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
                    border-radius: 6px;" :items="days2" v-model="day" :loading="loadingDays2"
                    :disabled="loadingDays2 || month === null" item-text="name" label="Día" return-object required solo
                    filled>
                  </v-select>
                </v-col>

                <v-col>
                  <v-select :rules="yearRules" style="max-width: 130px; height: 57px; background: #FFFFFF; border: 1.5px solid rgba(142, 142, 142, 0.2); box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
                    border-radius: 6px;" value="2022" :items="years" v-model="year" item-text="name" label="Año"
                    return-object required solo filled></v-select>
                </v-col>

              </v-row>
              <br><br>
              <v-text-field class="v-text-field-style" v-model="userData.email" :rules="emailRules2" label="Correo" required solo filled></v-text-field>
              </div>

              <!-- <div v-if="clienteNuevo == 'no'">
                <v-select class="v-select-style"  :items="userClients" v-model="clientId" 
                  label="Selecciona un Cliente" return-object required
                  solo filled no-data-text="No hay Clientes Disponibles"  item-text="fullname"></v-select>
              </div> -->

              <br><br>
              <!-- <h3 class="h3-text">Nombre del corredor</h3>
              <br>
              <v-text-field class="v-text-field-style" v-model="companyData.corredorName" :rules="nameRules" label="Nombre del corredor" required solo filled></v-text-field>
              -->

              <div class="step-data">
                <v-row justify="center">
                  <v-dialog v-model="dialog" width="600px">
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">Términos y Condiciones</span>
                      </v-card-title>
                      <v-card-text style="text-align: justify">
                        Por este acto, y según lo dispuesto en la Ley N° 19.628
                        sobre Protección de la Vida Privada y sus
                        modificaciones, autorizo expresamente a Southbridge
                        Compañía de Seguros Generales S.A. para que haga
                        tratamiento, almacene, transmita y comunique mis datos
                        personales y la información que le he proporcionado
                        voluntariamente, a su matriz, filiales, sociedades
                        coligadas, empresas relacionadas, sociedades del Grupo
                        Empresarial al que pertenece y terceros prestadores de
                        servicios, estén ubicados dentro o fuera de Chile, con
                        el propósito de que tanto Southbridge Compañía de
                        Seguros Generales S.A. como dichas entidades i) me
                        contacten y pongan a mi disposición, en la forma que
                        estimen pertinente, la más completa información y oferta
                        de sus productos y servicios; ii) procedan al
                        almacenamiento y tratamiento de mis datos personales y
                        de mi información personal para los objetivos del
                        cumplimiento de este Contrato de Seguro; y iii) hagan
                        efectivos el o los convenios que pudieren estar
                        asociados a los seguros que he contratado. Además, por
                        este acto, autorizo expresamente a que cualquier
                        información, comunicación, declaración o notificación
                        que debe efectuar Southbridge Compañía de Seguros
                        Generales S.A. en virtud del presente contrato de
                        seguros sea realizada a través de correo electrónico o
                        de cualquier sistema de transmisión o registro digital
                        de la palabra escrita o verbal o por cualquier otro
                        medio de comunicación fehaciente. El asegurado declara
                        que conoce, acepta y concuerda que cualquier transacción
                        que se realice en el sitio www.segurofacil.cl con su Rut
                        y clave personal, se entenderá y se reputará, para todos
                        los efectos legales, como realizada por él mismo. En tal
                        sentido, el asegurado se hace entera y totalmente
                        responsable del uso y seguridad de su clave, no
                        cabiéndole Southbridge Compañía de Seguros Generales
                        S.A.. ninguna responsabilidad por el uso indebido de la
                        misma por un tercero distinto del asegurado. El
                        asegurable se hace responsable por la veracidad de la
                        información entregada para la contratación del seguro y
                        del uso de las claves o códigos, liberando a Southbridge
                        Compañía de Seguros Generales S.A. de responsabilidad
                        por cualquier mal uso que hagan terceros de la
                        información que Southbridge Compañía de Seguros
                        Generales S.A. entregue exclusivamente a la persona del
                        asegurable.
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          style="
                            box-sizing: border-box;
                            padding: 12px 24px;
                            gap: 10px;
                            background: #ffb600;
                            border: 2px solid #ffb600;
                            border-radius: 8px;
                            margin-top: -6%;
                            font-family: Helvetica;
                            font-style: normal;
                            font-weight: 700;
                            font-size: 14px;
                            line-height: 17px;
                            letter-spacing: 0.02em;
                            color: white;
                            flex: none;
                            order: 0;
                            flex-grow: 0;
                            margin: auto;
                          "
                          elevation="0"
                          text
                          @click="dialog = false"
                        >
                          Aceptar
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-row>

                <v-hover v-if="screen > 800">
                  <v-checkbox :rules="conditionsRules" required>
                    <template v-slot:label>
                      <p class="p-text" style="max-width: 400px;margin-top: 10%;">
                        Al ingresar tus datos y hacer clic en continuar aceptas
                        que tu información sea utilizada para calcular tu
                        estimación de prima y comunicarnos contigo para éste y
                        otros productos de acuerdo a nuestros
                        <b
                          ><a style="color: #2d387c" @click="dialog = true"
                            >Términos y Condiciones</a
                          ></b
                        >
                      </p>
                    </template>
                  </v-checkbox>
                </v-hover>

                <v-hover v-else>
                  <v-checkbox :rules="conditionsRules" required>
                    <template v-slot:label>
                      <p p-text style="margin-top: 20%;">
                        Al ingresar tus datos y hacer clic en continuar aceptas
                        que tu información sea utilizada para calcular tu
                        estimación de prima, recomendar un seguro y poder
                        comunicarnos contigo de acuerdo a nuestros nuestros
                        <b
                          ><a style="color: #2d387c" @click="dialog = true"
                            >Términos y Condiciones</a
                          ></b
                        >
                      </p>
                    </template>
                  </v-checkbox>
                </v-hover>
              </div>

              <div class="button-container">
                <v-btn class="buttons" @click="validate()">Continuar</v-btn>
              </div>

            </v-form>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
import dateService from "@/services/date.service.js";
import planService from "@/services/plans.service.js";
import NavBar from "@/components/NavBar.vue";
import Titulo from "../components/Titulo.vue";
import RutUtils from "@/utils/rut.utils.js";
import yearService from "@/services/year.service.js";
import swal from 'sweetalert';
import ShieldService from '../services/shield.service';


export default {
  name: "Step1",
  components: { NavBar, Titulo },
  data: () => ({
    clienteNuevo: 'no',
    clienteNuevoError: 'Debe seleccionar una Empresa', 
    newCompany: 'no',
    finalUserName: null,
    version: null,
    role:null,
    key: '7cd49960-8289-4e75-adb9-d1503fec31e0',
    userRut:null,
    userId:null,
    passCompany: 0,
    passClient: 0,
    e1: 1,
    userData: {
      name: null,
      rut: null,
      email: null,
      dates: null,
      months: [],
      days: ["Selecciona el mes"],
      yars: [],
      companySponsor: null
    },
    dataQuote:{
      planType: null,
      daysNumber: null
    },
    companyData:{
      corredorName: null,
      name:null,
      rut:null,
      address: null, 
      comune: null,
      phoneNumber: null,
      email: null,
      companySponsor: null
    },
    dialog: false,
    companyId: null,
    clientId: null,
    userCompanies: [],
    companies: [],
    clients: [],
    userClients: [],
    screen: window.screen.width,
    plans: [],
    days: [],
    valid: false,
    month: null,
    day: null,
    year: null,
    months: [],
    days2: [],
    yars: [],
    cotizaciones: [],
    nameRules: [

    ],
    conditionsRules: [
      (v) => !!v || "Debe estar de acuerdo con los términos y condiciones",
    ],
    planRules: [(v) => !!v || "El plan es requerido"],
    daysRules: [
      (v) => !!v || "La cantidad de días es requerida",
      (v) => /^[0-9]*$/.test(v) || "Ingrese solo números",
      (v) => (!isNaN(v) && +v >= 40 && +v <= 9999) || "La cantidad de días debe ser un número entre 40 y 9999",
    ],
    rutRules: [],
    addressRules: [],
    comuneRules: [],
    emailRules: [
 
    ],
    phoneRules: [],
    newRut: "",
    nameRules2: [
      
    ],
    rutRules2: [
      
    ],
    emailRules2: [
      
    ],
    monthRules: [
      
    ],
    dayRules: [
      
    ],
    yearRules: [
      
    ]
  }),
  async mounted() {
    this.finalUserName = localStorage.getItem('finalUserName')
    localStorage.setItem('corredorName',this.finalUserName) 


    this.role = this.decrypt(localStorage.getItem('role'),this.key)


    this.companyData.corredorName = localStorage.getItem("corredorName");

    this.companyData.name = localStorage.getItem("companyName");
    this.companyData.rut = localStorage.getItem("companyRut");
    this.companyData.address = localStorage.getItem("companyAddress");
    this.companyData.comune = localStorage.getItem("companyComune");
    this.companyData.phoneNumber = localStorage.getItem("companyPhoneNumber");
    this.companyData.email = localStorage.getItem("companyEmail");

    this.userData.name = localStorage.getItem("userName");
    this.userData.rut = localStorage.getItem("userRut");
    this.userData.birthday = localStorage.getItem("userBirthday");
    this.userData.email = localStorage.getItem("userEmail");

    this.getPlans();
    this.getDays();
    this.getMonths();
    this.getYears();
    this.userRut = localStorage.getItem('finalUserRut')
    try {
        const userId = await this.getUserId();
        this.userId = userId;
        // console.log('this.userId: ', this.userId);
    } catch (error) {
        console.error('Error obteniendo userId:', error);
    }

    this.getQuotes();
    
    

  },
  methods: {
    encrypt(text, key) {
      let result = '';
      for (let i = 0; i < text.length; i++) {
          result += String.fromCharCode(text.charCodeAt(i) ^ key.charCodeAt(i % key.length));
      }
      return btoa(result); 
    },
    decrypt(text, key) {
      text = atob(text); 
      let result = '';
      for (let i = 0; i < text.length; i++) {
          result += String.fromCharCode(text.charCodeAt(i) ^ key.charCodeAt(i % key.length));
      }
      return result;
    },
    async getUserId(){
        await this.$recaptchaLoaded()
        const token = await this.$recaptcha('getUserId')
        try {
          const response = await ShieldService.getUserId(this.userRut,token);
          this.userData.companySponsor = response.sponsorsInsurance[0].sponsor
          this.companyData.companySponsor = response.sponsorsInsurance[0].sponsor
          return response._id;
      } catch (error) {
          console.error('Error obteniendo userId:', error);
          throw error;
      }
    },
    async getCompanies(){
      const response = await ShieldService.getCompanies(this.userData.companySponsor);
      this.userCompanies = response;
    },
    async getAllCompanies(){
      const response = await ShieldService.getAllCompanies();
      this.userCompanies = response;
    },
    // async getClients(){
    //   const response = await ShieldService.getClients(this.userClients);
    //   this.userClients = response;
    //   this.userClients.forEach(client => {
    //     if (client.surname){
    //       client.fullname = client.name.trim() + " " + client.surname.trim();
    //     }
    //     else{
    //       client.fullname = client.name.trim();
    //     }
    //   });
    // },
    async getQuotes(){
      await this.$recaptchaLoaded()
      const token = await this.$recaptcha('getUserQuotes')
      try {
          const response = await ShieldService.getUserQuotes(this.userId,token);
          this.cotizaciones = response
          if (this.role == 'Admin'){
            this.getAllCompanies()
          } else {
            this.getCompanies();
          }

      } catch (error) {
          console.error('Error obteniendo datos:', error);
          throw error;
      }
    },
    changeRut(rut) {
      this.companyData.rut = RutUtils.cleanRut(rut);
      this.newRut = RutUtils.formatRut(rut);
      this.companyData.rut = this.newRut;
    },
    changeRut2(rut) {
      this.userData.rut = RutUtils.cleanRut(rut);
      this.newRut = RutUtils.formatRut(rut);
      this.userData.rut = this.newRut;
    },
    SaveDates(dates) {
      this.$refs.menu.save(dates)
    },
    async validate() {
      if (this.$refs.form.validate()) {
        if(this.newCompany == 'si') {
            localStorage.setItem("companyName", this.companyData.name);
            localStorage.setItem("companyRut", this.companyData.rut);
            localStorage.setItem("companyAddress", this.companyData.address);
            localStorage.setItem("companyComune", this.companyData.comune);
            localStorage.setItem("companyPhoneNumber", this.companyData.phoneNumber);
            localStorage.setItem("companyEmail", this.companyData.email);
            localStorage.setItem("companySponsor", this.companyData.companySponsor);
        } else if(this.newCompany == 'no') {

            localStorage.removeItem("companyName");
            localStorage.removeItem("companyRut");
            localStorage.removeItem("companyAddress");
            localStorage.removeItem("companyComune");
            localStorage.removeItem("companyPhoneNumber");
            localStorage.removeItem("companyEmail");
            localStorage.removeItem("companySponsor");
            
            if(this.companyId != null){
              localStorage.setItem("companyRutSelected", this.companyId.rut);
              localStorage.setItem("companyName", this.companyId.name);
              localStorage.setItem("companyEmail", this.companyId.email);
              this.passCompany = 0

            } else {
              swal({
                title: "¡Ups!",
                text: "Por favor, selecciona o agrega una Empresa para continuar.",
                icon: "info",
                button: "Ok",
              });
              this.passCompany = 1
            }

        }
        localStorage.setItem("empresaNuevo", this.newCompany);


        if(this.clienteNuevo == "si"){
          const fechaActual = new Date();
          var newDate = this.year.id + "-" + this.month.number + "-" + this.day.id
          var diff = fechaActual.getFullYear() - this.year.id
          // console.log('Diff:',diff);
          if (diff < 18) {
            swal({
              title: "Atención!",
              text: "Para contratar este seguro, usted debe ser mayor de edad!",
              icon: "info",
              button: "De acuerdo",
            });
          } else {

            this.userData.dates = newDate
            localStorage.setItem("userName", this.userData.name);
            localStorage.setItem("userRut", this.userData.rut);
            // localStorage.setItem("userBirthday", this.userData.birthday);
            localStorage.setItem("userEmail", this.userData.email);
            localStorage.setItem("userDates", this.userData.dates)

            localStorage.setItem("corredorName", this.companyData.corredorName);

            localStorage.setItem("planType", this.dataQuote.planType);
            localStorage.setItem("daysNumber", this.dataQuote.daysNumber);

            localStorage.setItem("clienteNuevo", this.clienteNuevo)
            
          }
        } else if(this.clienteNuevo == 'no'){

          localStorage.removeItem("userName");
          localStorage.removeItem("userRut");
          localStorage.removeItem("dates");
          localStorage.removeItem("userEmail");

          localStorage.setItem("corredorName", this.companyData.corredorName);

          localStorage.setItem("planType", this.dataQuote.planType);
          localStorage.setItem("daysNumber", this.dataQuote.daysNumber);

          localStorage.setItem("clienteNuevo", this.clienteNuevo)          
        } 

        if(this.passClient != 1 && this.passCompany != 1){
            if (this.screen > 800) {
              // this.$router.push({path:"/planEscritorio"})
              this.$emit('cambiar-componente','step1');
            } else {
              // this.$router.push({path:"/planMovil"})
              this.$emit('cambiar-componente','step1');
            }
          }
      } 
    },
    async getPlans() {
      this.plans = await planService.getPlans();
    },
    async getDays() {
      this.loadingDays = true;
      this.days = await dateService.getDays();
      this.loadingDays = false;
    },
    async changeEsNuevoCliente(){
      localStorage.setItem("clienteNuevo", this.clienteNuevo)
      if(this.clienteNuevo == 'si'){
        this.nameRules2 = [
          (v) => !!v || "El nombre es requerido",
          (v) => /^[\p{L}\s-]+$/u.test(v) || "Ingrese solo letras",
          (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 carácteres",        
        ];
        this.rutRules2 = [
          (v) => !!v || "El RUT es requerido",
          (v) => RutUtils.validateRut(v) || "Rut no válido",       
        ];
        this.emailRules2 = [
          (v) => !!v || "El correo electrónico es requerido",
          (v) => /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(v) || "Ingrese un correo válido",
          (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 caracteres"        
        ];
        this.monthRules = [
          (v) => !!v || "El mes es requerido",
        ];
        this.dayRules = [
          (v) => !!v || "El día es requerido",
        ];
        this.yearRules = [
          (v) => !!v || "El año es requerido",
        ];
      } else {
        this.nameRules2 = [];
        this.rutRules2 = [];
        this.emailRules2 = [];
        this.monthRules = [];
        this.dayRules = [];
        this.yearRules = [];
      }
    },
    async changeEsNuevoCompany(){
      localStorage.setItem("empresaNuevo", this.newCompany)
      if(this.newCompany == 'si'){
        this.nameRules = [
          (v) => !!v || "El nombre es requerido",
          (v) => /^[\p{L}\s-]+$/u.test(v) || "Ingrese solo letras",
          (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 carácteres",
        ];
        this.rutRules = [
          (v) => !!v || "El RUT es requerido",
          (v) => RutUtils.validateRut(v) || "Rut no válido",
        ];
        this.addressRules = [
        (v) => /^[a-zA-Z\s-\d\u00C0-\u00FF]+$/.test(v) || "Ingrese solo números y letras",
          (v) => !!v || "Debes ingresar tu dirección",
          (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 carácteres"
        ];
        this.comuneRules = [
          (v) => !!v || "Debes ingresar tu comuna",
          (v) => /^[\p{L}\s-]+$/u.test(v) || "Ingrese solo letras",
          (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 carácteres"
        ];
        this.phoneRules = [
          (v) => !!v || "El número telefónico es requerido",
          (v) => /^[0-9]*$/.test(v) || "Ingrese solo números",
          (v) => (!!v && v.length == 9) || "Ingrese un número válido",
        ];
        this.emailRules = [
          (v) => !!v || "El correo electrónico es requerido",
          (v) => /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(v) || "Ingrese un correo válido",
          (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 caracteres"
        ];
      } else {
        this.nameRules = [];
        this.rutRules = [];
        this.addressRules = [];
        this.comuneRules = [];
        this.phoneRules = [];
        this.emailRules = [];
      }
    },
    async getMonths() {
      this.loadingMonths = true;
      this.months = await dateService.getMonths();
      this.loadingMonths = false;
    },
    async getDays2() {
      if (this.month) {
        this.loadingDays2 = true;
        this.day = null;
        this.days2 = await dateService.getDays2(
          this.month.name
        );
        this.loadingDays2 = false;
      }
    },
    async getYears() {
      this.loadingYears = true;
      this.years = await yearService.getYears();
      this.loadingYears = false;
    },
  },
};
</script>

<style lang="scss">
.step-date {
  max-width: 200px;
  align-items: center;
  display: flex;
  margin-left: 2rem;
  justify-content: center;

  .v-select {
    max-width: 150px;
  }
}

.form-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 800px;
}

#app
  > div
  > main
  > div
  > div
  > div.v-stepper.v-stepper--alt-labels.v-sheet.theme--light.elevation-0
  > div.v-stepper__header
  > div:nth-child(1)
  > span {
  width: 39.89px;
  height: 40px;
  font-size: 2rem;
  margin-top: -8%;
  font-family: "Verdana";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: #fcfcfc;
}

#app
  > div
  > main
  > div
  > div
  > div.v-stepper.v-stepper--alt-labels.v-sheet.theme--light.elevation-0
  > div.v-stepper__header
  > div:nth-child(3)
  > span {
  width: 39.89px;
  height: 40px;
  font-size: 2rem;
  margin-top: -8%;
  font-family: "Verdana";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: #fcfcfc;
}

#app
  > div
  > main
  > div
  > div
  > div.v-stepper.v-stepper--alt-labels.v-sheet.theme--light.elevation-0
  > div.v-stepper__header
  > div:nth-child(5)
  > span {
  width: 39.89px;
  height: 40px;
  font-size: 2rem;
  margin-top: -8%;
  font-family: "Verdana";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: #fcfcfc;
}

#app
  > div
  > main
  > div
  > div
  > div.v-stepper.v-stepper--alt-labels.v-sheet.theme--light.elevation-0
  > div.v-stepper__header
  > div:nth-child(7)
  > span {
  width: 39.89px;
  height: 40px;
  font-size: 2rem;
  margin-top: -8%;
  font-family: "Verdana";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: #fcfcfc;
}

.titulo {
  background-image: url("~@/assets/images/titulo2.png");
  max-width: 350px;
  height: 150px;
  margin: auto;
}

.v-input .v-label {
  font-size: 12px;
  color: #8e8e8e;
}

.h3-text{
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 15px;
  letter-spacing: -0.01em;
  color: #2d387c;
}

.h4-text{
  font-family: Helvetica;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  line-height: 16px;
  color: #232323;
  opacity: 0.75;
  padding: 2%;
}

.step-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .back-container {
    width: 100%;
    align-items: flex-start;

    .back-button {
      &:hover {
        opacity: 1;
      }
    }
  }

  .buttons{
    background-color: #2d387c !important;
    color: white !important;
    border-radius: 12px;
    width: 80% !important;
    height: 120% !important;
  }

  .button-container {
    text-align: center;
  }

  .forecast-selector {
    text-transform: uppercase;
    font-weight: 600;
    color: #464e71;
  }
}

.v-btn__content {
  padding: 0.75rem;
}

.v-select-style{
  height: 57px;
  background: #ffffff;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}
.v-text-field-style{
  box-sizing: border-box;
  height: 57px;
  background: #ffffff;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 0 6px 6px 0;
}
.p-text{
  text-align: center;
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 143.34%;
  letter-spacing: -0.3px;
  color: #232323;
  opacity: 0.8;
  text-align: justify;
}
</style>