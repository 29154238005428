import companiesData from "../utils/companies-data";

export default class dateService {
  static async getCompanies() {
    try {
      let companies = companiesData;
      return companies;
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener las empresas",
      };
    }
  }
}