// import dateData from "../utils/days-data";

export default class CommissionService {
  
    static async getCommission() {
      try {
        var commissions = [];
        for (var i = 0; i <= 30;  i+=1) {
            commissions.push(i+"%");
        }
        // let months;
        // if (typeof dateId === "string" || dateId instanceof String) {
        //   months = dateData.find((element) => element.name === dateId);
        // } else if (!isNaN(parseFloat(dateId)) && !isNaN(dateId - 0)) {
        //   months = dateData.find((element) => element.number === dateId);
        // } else {
        //   return [];
        // }
        // let days = months.days ? months.days : [];
        return commissions;
      } catch (error) {
        return {
          status: "error",
          message: "Ocurrio un error al obtener las comisiones",
        };
      }
    }
  }